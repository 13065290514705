import React, { Component } from 'react';
import { object } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import { Button, Table } from 'antd';

class ProcessType extends Component {
  static propTypes = {
    processType: object
  };

  renderStageTemplates = () => {
    const { processType: { stageTemplates } } = this.props;
    const columns = [
      {
        title: 'Posición',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: 'Nombre',
        dataIndex: ['metaStage', 'name']
      },
      {
        title: 'Duración',
        dataIndex: 'days',
        key: 'days'
      },
      {
        title: 'Días antes',
        dataIndex: 'minDays',
        key: 'minDays'
      },
      {
        title: 'Días después',
        dataIndex: 'maxDays',
        key: 'maxDays'
      }
    ];

    return (
      <Table
        pagination={{ pageSize: 20 }}
        locale={{ emptyText: 'Sin etapas' }}
        showSorterTooltip={false}
        dataSource={sortBy(stageTemplates, 'position')}
        columns={columns}
      />
    );
  };

  render () {
    const { processType: { id, name, description } } = this.props;
    return (
      <>
        <h2>{name}</h2>
        <p>
          {description}
        </p>
        <h3>Etapas</h3>
        {this.renderStageTemplates()}
        <Link to={`/staff/process_types/${id}/edit`} >
          <Button className="mt-2">
            Editar
          </Button>
        </Link>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getProcessType(id);
    },
    'processType'
  )
)(ProcessType);
