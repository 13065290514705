import React, { Component } from 'react';
import { Card, Button, Row, Col, Divider } from 'antd';
import { object } from 'prop-types';

import SaleProcessForm from '../sale-process-form';

class SaleProcessShow extends Component {
  static propTypes = {
    saleProcess: object
  };

  state = {
    edit: false
  };

  mainComponent = () => {
    const { edit } = this.state;
    const { saleProcess } = this.props;
    if (edit) {
      return (
        <SaleProcessForm action="edit" saleProcess={saleProcess}
          onEdit={() => {
            this.setState({ edit: false });
          }}/>
      );
    }
    const {
      supervisorName,
      fullAddress,
      processTypeName,
      value,
      currency,
      sheet,
      number,
      year,
      description,
      tags,
      roles,
      seller,
      buyer,
      creditInfo,
      debtInfo
    } = saleProcess;
    return (
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Divider orientation="left">Datos propiedad</Divider>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Ejecutivo</h3>
            {supervisorName}
          </Card>
        </Col>
        <Col span={18}>
          <Card>
            <h3>Calle</h3>
            {fullAddress}
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row gutter={4}>
              <Col span={6}>
                <h3>Tipo de proceso</h3>
                {processTypeName}
              </Col>
              <Col span={6}>
                <h3>Valor</h3>
                {value} {currency === 'uf' ? 'UF' : 'CLP'}
              </Col>
              <Col span={6}>
                <h3>Foja</h3>
                {sheet}
              </Col>
              <Col span={6}>
                <h3>Número</h3>
                {number}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Año</h3>
            {year}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Tags</h3>
            <span>{tags?.join(', ')}</span>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h3>Observaciones</h3>
            {description}
          </Card>
        </Col>
        <Col span={24}>
          <Divider orientation="left">Roles</Divider>
        </Col>
        {
          roles.map(
            ({ number, description }) => (
              <Col span={24} key={number}>
                <Card>
                  <Row gutter={4}>
                    <Col span={6}>
                      <h3>Rol</h3>
                      {number}
                    </Col>
                    <Col span={18}>
                      <h3>Descripción</h3>
                      {description}
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          )
        }
        <Col span={24}>
          <Divider orientation="left">Datos vendedor</Divider>
        </Col>
        <Col span={24}>
          {this.userComponent(seller)}
        </Col>
        <Col span={24}>
          <Divider orientation="left">Datos comprador</Divider>
        </Col>
        <Col span={24}>
          {this.userComponent(buyer)}
        </Col>
        {
          creditInfo && <>
            <Col span={24}>
              <Divider orientation="left">Información del crédito</Divider>
            </Col>
            <Col span={24}>
              {this.bankInfoComponent(creditInfo)}
            </Col>
          </>
        }
        {
          debtInfo && <>
            <Col span={24}>
              <Divider orientation="left">Información de la deuda</Divider>
            </Col>
            <Col span={24}>
              {this.bankInfoComponent(debtInfo)}
            </Col>
          </>
        }
      </Row>
    );
  };

  bankInfoComponent = (bankInfo) => {
    const {
      bankName,
      agentName,
      agentEmail,
      agentPhoneNumber
    } = bankInfo;
    return (
      <Row gutter={[6, 6]}>
        <Col span={6}>
          <Card>
            <h3>Institución</h3>
            {bankName}
          </Card>
        </Col>
        <Col span={18}>
          <Card>
            <Row gutter={[6, 6]}>
              <Col span={8}>
                <h3>Nombre ejecutivo</h3>
                {agentName}
              </Col>
              <Col span={8}>
                <h3>Email ejecutivo</h3>
                {agentEmail}
              </Col>
              <Col span={8}>
                <h3>Teléfono ejecutivo</h3>
                {agentPhoneNumber}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  };

  userComponent = (user) => {
    const {
      rut,
      firstName,
      lastName,
      phoneNumber,
      email,
      interestedEmails,
      street,
      commune,
      region,
      occupation,
      nationality
    } = user || {
      rut: 'Sin Rut',
      firstName: 'Sin Nombre',
      lastName: 'Sin Apellido ',
      phoneNumber: 'Sin número',
      email: 'Sin email',
      interestedEmails: [],
      street: 'Sin calle',
      region: 'Arica y Parinacota',
      commune: 'Arica',
      occupation: 'Sin ocupación',
      nationality: 'Chile'
    };
    return (
      <Row gutter={[6, 6]}>
        <Col span={6}>
          <Card>
            <h3>RUT</h3>
            {rut}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Nombre</h3>
            {firstName} {lastName}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Teléfono</h3>
            {phoneNumber || '-'}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Emails</h3>
            {email}
            {interestedEmails.map((email) => (
              <>
                <br/> {email}
              </>
            ))}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>Ocupación/Profesión</h3>
            {occupation}
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3>País de origen</h3>
            {nationality}
          </Card>
        </Col>
        {
          street && (
            <Col span={24}>
              <Card>
                <h3>Dirección</h3>
                {street}, {commune}, {region}
              </Card>
            </Col>
          )
        }
      </Row>
    );
  }

  render () {
    const { edit } = this.state;
    return (
      <Row gutter={[6, 6]} className="p-2">
        <Col span={24}>
          <Button onClick={() => this.setState({ edit: !edit })}>
            {
              edit ? 'Cancelar' : 'Editar'
            }
          </Button>
        </Col>
        <Col span={24}>
          {this.mainComponent()}
        </Col>
      </Row>
    );
  }
}

export default SaleProcessShow;
