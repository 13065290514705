import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import MetaStageForm from '../../../components/staff/meta-stage-form';

class MetaStageEdit extends Component {
  static propTypes = {
    metaStage: object
  };

  render () {
    const { metaStage } = this.props;
    return (
      <>
        <h2>Editar {name}</h2>
        <MetaStageForm action="edit" metaStage={metaStage}/>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getMetaStage(id);
    },
    'metaStage'
  )
)(MetaStageEdit);
