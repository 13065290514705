import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { func } from 'prop-types';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { history } from '../../common/custom-router';
import { staffLogin } from '../../../store/actions/session';

import logo from '../../../assets/images/ControlPV-logo-login.svg';

const { Text } = Typography;

class LoginForm extends Component {
  static propTypes = {
    login: func
  };

  state = {
    error: false
  };

  handleSubmit = ({ email, password }) => {
    this.props.login(email, password).then(() => {
      this.setState({ error: false });
      history.push('/staff/sale_processes');
    }).catch(() => {
      this.setState({ error: true });
    });
  };

  render () {
    const { error } = this.state;
    return (
      <Form className="login-form" type="flex" justify="center" align="middle" onFinish={this.handleSubmit} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autoComplete="off" >
        <Form.Item type="flex" justify="center" >
          <img src={logo} alt='logo-login'/>
        </Form.Item>
        <Form.Item justify="center" align="middle" name="email" rules={[{ required: true, message: 'Por favor ingrese su email.' }]}>
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email"/>
        </Form.Item>
        <Form.Item justify="center" align="middle" name="password" rules={[{ required: true, message: 'Por favor ingrese su contraseña.' }]}>
          <Input prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        {
          error &&
            <Form.Item justify="center">
              <Text type="danger">
                Email o contraseña inválidos
              </Text>
            </Form.Item>
        }
        <Form.Item type="flex" justify="center" align="middle">
          <Button type="primary" htmlType="submit" style={{ width: '169px', height: '48px' }}>
              Entrar
          </Button>
        </Form.Item>
        <Link to="/staff/user/password/new">
          ¿Olvidaste tu contraseña?
        </Link>
      </Form>
    );
  }
}

const mapDispatchToProps = {
  login: staffLogin
};

export default connect(null, mapDispatchToProps)(LoginForm);
