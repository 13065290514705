import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { resetImpersonation } from '../../../store/actions/session';
import { history } from '../../common/custom-router';

class ImpersonationPanel extends Component {
  static propTypes = {
    currentAdmin: object,
    currentUser: object,
    resetImpersonation: func
  };

  render () {
    const { currentAdmin, currentUser, resetImpersonation } = this.props;
    if (currentUser && currentAdmin) {
      const { email: adminEmail } = currentAdmin;
      const { email: userEmail } = currentUser;
      return (
        <Alert className="mt-2 mb-2" message={
          (
            <>
              Tú ({adminEmail}) has iniciado sesión
              como {userEmail} <Button type="link" onClick={() => {
                resetImpersonation();
                history.push('/support/companies');
              }}>
                Volver al panel
              </Button>
            </>
          )
        } type="info" />
      );
    } else {
      return '';
    }
  }
}

const mapStateToProps = (state) => ({
  currentAdmin: state.session && state.session.currentAdmin,
  currentUser: state.session && state.session.currentUser
});

const mapDispatchToProps = {
  resetImpersonation
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ImpersonationPanel);
