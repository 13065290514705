import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import ProcessTypeForm from '../../../components/staff/process-type-form';

class ProcessTypeEdit extends Component {
  static propTypes = {
    processType: object
  };

  render () {
    const { processType } = this.props;
    const { name } = processType;
    return (
      <>
        <h2>Editar {name}</h2>
        <ProcessTypeForm action="edit" processType={processType}/>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getProcessType(id);
    },
    'processType'
  )
)(ProcessTypeEdit);
