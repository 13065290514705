import React, { Component } from 'react';
import SaleProcessesList from '../../../components/staff/sale-processes-list';

class EndedSaleProcesses extends Component {
  render () {
    return (
      <>
        <SaleProcessesList ended/>
      </>
    );
  }
}

export default EndedSaleProcesses;
