import React, { Component } from 'react';
import { Button, Form, message, Upload, Card, Row, Col, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import { object, number } from 'prop-types';
import { connect } from 'react-redux';
import { promiseLoader, refreshAll } from '../../../components/common/promise-loader';
import compose from 'lodash/fp/compose';

import instagramLogo from '../../../assets/images/instagram-logo-black.svg';
import linkedinLogo from '../../../assets/images/linkedin-logo-black.svg';
import facebookLogo from '../../../assets/images/facebook-logo-black.svg';
import webpageIcon from '../../../assets/images/webpage-icon-black.svg';

import API from '../../../api';
import StaffMailerIndex from '../../../components/staff/mailer/mailer-edit';

const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

class CompanyEdit extends Component {
  static propTypes = {
    companyId: number,
    location: object,
    company: object
  };

  state = {
    logo: null
  };

  handleSubmit = (attributes) => {
    const { companyId } = this.props;
    const { logo } = this.state;
    const company = {
      ...attributes
    };
    if (logo) {
      company.logo = logo;
    }
    API.staff.updateCompanyMultipart(companyId, company).then(() => {
      refreshAll();
      message.success('Datos actualizados');
    }).catch(() => {
      message.error('Hubo un error al actualizar los datos');
    });
  };

  getInitialValues = () => {
    const {
      company:
      { webpageUrl, linkedinUrl, instagramUrl, facebookUrl }
    } = this.props;

    return {
      webpageUrl,
      linkedinUrl,
      instagramUrl,
      facebookUrl
    };
  };

  beforeUpload = (logo) => {
    this.setState({ logo });
    return false;
  };

  onRemove = () => {
    this.setState({ logo: null });
  };

  render () {
    const { file } = this.state;

    return (
      <>
        <h1 className='text-center mt-3'>Configuración</h1>
        <Row gutter={10} className='justify-content'>
          <Col span={12}>
            <Card className='p-3'>
              <h2 className='text-center mt-3'>Modificar empresa</h2>
              <Form className='text-center' layout="vertical" onFinish={this.handleSubmit} autoComplete="off" requiredMark="optional" initialValues={this.getInitialValues()}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<><img src={webpageIcon}/><span className="ml-3">Sitio web</span></>}
                      name="webpageUrl"
                    >
                      <Input addonBefore="https://"/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<><img src={facebookLogo}/><span className="ml-3">Facebook</span></>}
                      name="facebookUrl"
                    >
                      <Input addonBefore="facebook.com/"/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<><img src={instagramLogo}/><span className="ml-3">Instagram</span></>}
                      name="instagramUrl"
                    >
                      <Input addonBefore="instagram.com/"/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<><img src={linkedinLogo}/><span className="ml-3">Linkedin</span></>}
                      name="linkedinUrl"
                    >
                      <Input addonBefore="linkedin.com/in/"/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Logo de Empresa"
                      className="text-left"
                      valuePropName="fileList"
                      required={true}
                      getValueFromEvent={normFile}
                    >
                      <Upload beforeUpload={this.beforeUpload} onRemove={this.onRemove} fileList={ file ? [file] : []}>
                        <Button>
                          Elegir logo
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className='text-right'>
                  <Button type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <StaffMailerIndex/>
          </Col>
        </Row>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.session.currentUser && state.session.currentUser.companyId
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  promiseLoader(
    ({ companyId }) => {
      if (companyId) {
        return API.staff.getCompany(companyId);
      } else {
        return null;
      }
    },
    'company', true)
)(CompanyEdit);
