import React, { Component } from 'react';
import { Button, Form, Input, message } from 'antd';
import API from '../../../api';

class PasswordNew extends Component {
  handleSubmit = ({ email }) => {
    API.staff.createPassword(email).then(() => {
      message.success('Si tu correo existe en nuestra base de datos, recibirás un correo con instrucciones sobre cómo resetear tu contraseña en tu bandeja de entrada');
    }).catch(() => {
      message.error('Hubo un error al recuperar contraseña');
    });
  };

  render () {
    return (
      <>
        <h1>Modificar contraseña</h1>
        <Form layout="vertical" onFinish={this.handleSubmit} autocomplete="off" requiredMark="optional">
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'El email no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enviar instrucciones
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default PasswordNew;
