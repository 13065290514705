import React, { Component } from 'react';
import { Col, Form, Input, Select, Divider } from 'antd';
import { BANK_NAMES } from '../../../constants/bank.js.erb';

const { Option } = Select;

class CreditInfoAttributes extends Component {
  render () {
    return (
      <>
        <Col span={24}>
          <Divider orientation="left">Información del crédito</Divider>
        </Col>
        <Form.Item label="ID" name={['creditInfoAttributes', 'id']} hidden>
          <Input/>
        </Form.Item>
        <Col span={6}>
          <Form.Item label="Institución" name={['creditInfoAttributes', 'bankName']}>
            <Select placeholder="Seleccione Banco">
              {
                BANK_NAMES.map((bank) => (
                  <Option value={bank} key={`credit-${bank}`}>{bank}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nombre ejecutivo" name={['creditInfoAttributes', 'agentName']}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Teléfono" name={['creditInfoAttributes', 'agentPhoneNumber']}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email" name={['creditInfoAttributes', 'agentEmail']}>
            <Input/>
          </Form.Item>
        </Col>
      </>
    );
  }
}

export default CreditInfoAttributes;
