import React from 'react';

export const getErrorElement = (err) => {
  const { response } = err;
  if (!response) return 'Error al enviar la solicitud.';
  let errors;
  switch (response.status) {
    case 422:
      errors = response.data;
      return (
        <ul>
          {
            errors.map((error, index) => {
              return (
                <li key={`form-error-${index}`}>
                  <span>
                    {error}
                  </span>
                </li>
              );
            })
          }
        </ul>
      );
    case 500:
      return (
        <>
          <span>Hubo un error en el servidor.</span>
          <br/>
          <span>Por favor contáctate con el administrador.</span>
        </>
      );
    default:
      return err.message;
  }
};
