import React, { Component } from 'react';
import { object } from 'prop-types';
import { orderBy } from 'lodash';
import moment from 'moment';
import { Steps, Card, Row, Col } from 'antd';

import CardBinnacle from '../card-binnacle';
import CardAttachments from '../card-attachments';

import ClientLayout from '../../../components/common/client-layout';

import iconRes from '../../../assets/images/sales-processes-user.svg';
import arrowIcon from '../../../assets/images/icons/arrow-icon.svg';
import iconResMobile from '../../../assets/images/icons/client-resp-icon-mobile.svg';
import { userAgentIsMobile } from '../../../utils/navigation';
import './index.scss';

const { Step } = Steps;

class SaleProcess extends Component {
  static propTypes = {
    saleProcess: object
  };

  constructor (props) {
    super(props);
    const { saleProcess: { stages, currentStageId } } = props;
    const currentStage = stages.find(({ id }) => id === currentStageId);
    const currentStep = orderBy(stages, 'position').indexOf(currentStage);

    this.state = {
      currentStep
    };
  }

  getStepStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'wait';
      case 'finished':
        return 'finish';
      case 'cancelled':
        return 'error';
      case 'ongoing':
        return 'process';
    }
  };

  renderStages = () => {
    const { saleProcess: { stages } } = this.props;
    const { currentStep } = this.state;

    let stepPropety = {
      direction: 'horizontal',
      typeStep: 'navigation',
      size: 'small',
      responsive: 'true'
    };

    if (userAgentIsMobile()) {
      stepPropety = {
        direction: 'vertical',
        typeStep: '',
        size: '',
        responsive: 'false'
      };
    }

    return (
      <Steps type={stepPropety.typeStep} direction={stepPropety.direction} responsive={stepPropety.responsive} size={stepPropety.size} current={currentStep} onChange={(currentStep) => this.setState({ currentStep })}>
        {
          orderBy(stages, 'position').map(
            ({ id, name, days, status }) => (
              <Step key={`stage-${id}`} status={this.getStepStatus(status)} title={name}/>
            )
          )
        }
      </Steps>
    );
  };

  renderNavStep = () => {
    const { saleProcess: { stages } } = this.props;
    const { saleProcess } = this.props;
    const { currentStep } = this.state;
    const { supervisor } = saleProcess;
    const stage = orderBy(stages, 'position')[currentStep];
    const { name } = stage;

    const updateCurrentStep = (direction) => {
      if (direction === -1) {
        if (currentStep - 1 >= 0) {
          this.setState({ currentStep: currentStep - 1 });
        }
      }

      if (direction === 1) {
        if (currentStep + 1 < stages.length) {
          this.setState({ currentStep: currentStep + 1 });
        }
      }
    };

    return (
      <>
        <h2 className='tittle-name'>{saleProcess.name}</h2>
        <Row className='m-3 w-auto' justify='center' align='center' >
          <Col className='d-flex align-items-center justify-content-center' span={9}>
            <img src={iconResMobile} alt="res"/>
          </Col>
          <Col span={15}>
            <div>
              <strong>Ejecutivo: </strong>{supervisor.firstName} {supervisor.lastName} <br/>
              {supervisor.email} <br/>
              {
                supervisor.phoneNumber && (
                  <a href={`https://wa.me/${supervisor.phoneNumber.replace('+', '')}`}>{supervisor.phoneNumber}</a>
                )
              }
            </div>
          </Col>
        </Row>

        <Card>
          <Row align='center' justify='center'>
            <Col className='d-flex align-items-center' span={2}>
              <img src={arrowIcon} alt="" style={{ transform: 'rotate(180deg)' }} onClick={() => updateCurrentStep(-1)}/>
            </Col>
            <Col className='d-flex flex-column align-items-center justify-content-center' span={20}>
              <h3 className='tittle-current-step'>Etapa Actual</h3>
              <p><span className='circle'>{currentStep + 1}</span></p>
              <p className='text-strong' style={{ fontSize: '15px' }}>{name}</p>
            </Col>
            <Col className='d-flex align-items-center' span={2}>
              <img src={arrowIcon} alt="" onClick={() => (updateCurrentStep(1))}/>
            </Col>
          </Row>
        </Card>
        <div className='d-flex flex-column align-items-center'>
          <div className='triangle'></div>
        </div>
      </>
    );
  }

  renderSelectedStage = () => {
    const { saleProcess: { stages } } = this.props;
    const { currentStep } = this.state;
    const stage = orderBy(stages, 'position')[currentStep];
    const { name, description, startDate, estimatedEndDate, comments, attachments, responsible } = stage;
    return (
      <>
        <div className='d-flex justify-content-around mt-2 flex-wrap' >
          <Card className='card-description mt-3' style={{ width: '50%' }}>
            <strong className='tittle-description'>{name}</strong>
            <p>{description}</p>
          </Card>

          <Card className='card-responsible mt-3' justify="center" align="middle" style={{ width: '258px' }}>
            <img src={iconRes} alt="icon" className='card-item' style={{ marginBottom: '5px' }}/>
            <p className='card-item'><strong>Responsables:</strong> {responsible} </p>
            <p className='card-item'><strong>Fecha de inicio:</strong> {startDate ? moment(startDate, 'YYYY/MM/DD').format('DD/MM/YYYY') : '-'}</p>
            <p className='card-item'><strong>Fecha pronosticada:</strong> {moment(estimatedEndDate, 'YYYY/MM/DD').format('DD/MM/YYYY')}</p>
          </Card>
        </div>

        <div className='d-flex justify-content-around flex-wrap'>
          <div className='mt-3' align='middle' style={{ width: '502px' }}>
            <h2 className='tittle-binnacle'>Bitácora</h2>
            {comments[0] ? (
              comments.map(item => (
                <CardBinnacle
                  date={item.createdAt}
                  authorEmail={item.author.email}
                  comment={item.content}
                  key={item.createdAt}
                />
              ))
            ) : (
              <p>Sin comentarios</p>
            )}
          </div>

          <div className='mt-3' align='middle' style={{ width: '502px' }}>
            <h2 className='tittle-attachments'>Anexos</h2>
            {
              attachments[0] ? (
                attachments.map(item => (
                  <CardAttachments
                    description={item.description}
                    type={item.attachmentKind.name}
                    downloadUrl={item.downloadUrl}
                    key={item.downloadUrl}
                  />
                ))
              ) : (
                <p>Sin anexos</p>
              )
            }
          </div>
        </div>
      </>
    );
  };

  render () {
    const { saleProcess } = this.props;
    const { name, company, supervisor, additionalSupervisors } = saleProcess;
    const isMobile = (userAgentIsMobile());

    return (
      <ClientLayout title={name} company={company} supervisor={supervisor} additionalSupervisors={additionalSupervisors} isMobile={isMobile}>
        {
          isMobile ? (
            <>
              {this.renderNavStep()}
              {this.renderSelectedStage()}
              <h3 className='tittle-steps mt-5'>Avance Etapas</h3>
              {this.renderStages()}
            </>

          ) : (
            <>
              {this.renderStages()}
              {this.renderSelectedStage()}
            </>
          )
        }
      </ClientLayout>
    );
  }
}

export default SaleProcess;
