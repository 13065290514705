import React, { Component } from 'react';
import CompaniesList from '../../../components/support/companies-list';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

class StaffCompaniesIndex extends Component {
  render () {
    return (
      <>
        <h3>Empresas</h3>
        <Link to="/support/companies/create">
          <Button className="mb-2">
            Crear empresa
          </Button>
        </Link>
        <CompaniesList/>
      </>
    );
  }
}

export default StaffCompaniesIndex;
