import React, { Component, createRef } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select
} from 'antd';
import { object, array } from 'prop-types';
import API from '../../../api';
import { refreshAll } from '../../common/promise-loader';
import editIcon from '../../../assets/images/edit-icon.svg';

const { Option } = Select;

class EditAttachmentButton extends Component {
  static propTypes = {
    attachment: object,
    attachmentKinds: array,
    stages: array
  };

  formRef = createRef();

  constructor (props) {
    super(props);
    const {
      attachment: {
        sellerVisible,
        buyerVisible
      }
    } = this.props;
    this.state = {
      visible: false,
      sellerVisible,
      buyerVisible
    };
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  handleOk = () => {
    this.formRef.submit();
  };

  handleCancel = () => {
    this.closeModal();
  };

  handleSubmit = ({ description, stageId, attachmentKindId }) => {
    const {
      attachment: {
        id
      }
    } = this.props;
    const {
      sellerVisible,
      buyerVisible
    } = this.state;

    API.staff.updateAttachment(id, {
      description,
      stageId,
      attachmentKindId,
      sellerVisible,
      buyerVisible
    }).then(() => {
      this.setState({ visible: false });
      refreshAll();
      message.success('Anexo editado correctamente');
    });
  };

  getInitialValues = () => {
    const { attachment } = this.props;
    const { stageId, description, attachmentKindId } = attachment;
    return {
      description,
      attachmentKindId,
      stageId
    };
  };

  render () {
    const { attachmentKinds, stages } = this.props;
    const { sellerVisible, buyerVisible } = this.state;
    return (
      <>
        <Button type="link" onClick={this.showModal}>
          <img src={editIcon} alt="edit icon"/>
        </Button>
        <Modal
          title="Editar comentario"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={920}
        >
          <Form layout="vertical" onFinish={this.handleSubmit} initialValues={this.getInitialValues()} ref={(ref) => { this.formRef = ref; }} autocomplete="off">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Tipo" name="attachmentKindId">
                  <Select>
                    {
                      attachmentKinds.map(
                        ({ id, name }) => (
                          <Option key={`attachment-kind-${id}`} value={id}>
                            {name}
                          </Option>
                        )
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Etapa" name="stageId">
                  <Select>
                    {
                      stages.map(
                        ({ id, name }) => (
                          <Option key={`stage-${id}`} value={id}>
                            {name}
                          </Option>
                        )
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Descripción" name="description">
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Visible vendedor">
                  <Checkbox checked={sellerVisible} onChange={({ target: { checked: visible } }) => this.setState({ sellerVisible: visible })}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Visible comprador">
                  <Checkbox checked={buyerVisible} onChange={({ target: { checked: visible } }) => this.setState({ buyerVisible: visible })}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditAttachmentButton;
