import React, { Component } from 'react';
import { Card, Col, Row } from 'antd';
import { string } from 'prop-types';

import './index.scss';

import icon1 from '../../../assets/images/icons/attachments-icon1.svg';
import icon2 from '../../../assets/images/icons/attachments-icon2.svg';
import icon3 from '../../../assets/images/icons/attachments-icon3.svg';

class CardAttachments extends Component {
  static propTypes = {
    description: string,
    type: string,
    downloadUrl: string
  };

  render () {
    const { description, type, downloadUrl } = this.props;
    return (
      <Card className='card-attachments' style={{ marginTop: '20px' }}>
        <Row className='text-left'>
          <Col span={9}>
            <img src={icon1} alt="icon1"/>
            <strong className='text-strong'> Descripción </strong>
          </Col>
          <Col span={15}>
            {
              description === 'undefined' ? (
                <p>Sin descripción</p>
              ) : (
                <p>{description}</p>
              )
            }
          </Col>

          <Col span={9}>
            <img src={icon2} alt="icon2"/>
            <strong className='text-strong'> Tipo </strong>
          </Col>
          <Col span={15}>
            <p>{type}</p>
          </Col>

          <Col span={9}>
            <img src={icon3} alt="icon3"/>
            <strong className='text-strong'> Acciones </strong>
          </Col>
          <Col span={15}>
            <a href={downloadUrl}>Descargar</a>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default CardAttachments;
