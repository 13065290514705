import React, { Component } from 'react';
import { array } from 'prop-types';
import { Button, Modal, Table, Tag } from 'antd';

import API from '../../../api';
import { promiseLoader, refreshAll } from '../../common/promise-loader';
import { history } from '../../common/custom-router';
import deleteIcon from '../../../assets/images/sales-processes-delete.svg';

class CompaniesList extends Component {
  static propTypes = {
    companies: array
  };

  onDelete = (record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar esta empresa?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        await API.support.deleteCompany(record.id);
        refreshAll();
      }
    });
  };

  render () {
    const { companies } = this.props;
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Postventas',
        dataIndex: 'saleProcessesCount',
        key: 'saleProcessesCount'
      },
      {
        title: 'Último login',
        dataIndex: 'lastSignInAt',
        key: 'lastSignInAt'
      },
      {
        title: 'Última modificación',
        dataIndex: 'lastEntityCreatedAt',
        key: 'lastEntityCreatedAt'
      },
      {
        title: 'Tags',
        render: (_, { id, tags }) => (
          <>
            {tags.map((tag) => <Tag key={`${id}-${tag}`}>{tag}</Tag>)}
          </>
        )
      },
      {
        title: 'Acciones',
        render: (_, record) => (
          <Button type="link" onClick={this.onDelete(record)}>
            <img src={deleteIcon} alt="delete icon"/>
          </Button>
        )
      }
    ];
    return (
      <div>
        <Tag className="mt-3 mb-2" color= "blue">
          Postventas totales: {companies.reduce((acc, { saleProcessesCount }) => acc + saleProcessesCount, 0)}
        </Tag>
        <Table
          locale={{ emptyText: 'Sin empresas' }}
          showSorterTooltip={false}
          dataSource={companies}
          columns={columns}
          onRow={(record) => ({
            onClick: () => history.push(`/support/companies/${record.id}`)
          })}
        />
      </div>
    );
  }
}

export default promiseLoader(
  () => API.support.getCompanies(),
  'companies'
)(CompaniesList);
