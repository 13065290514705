import React, { Component } from 'react';
import { Button, Form, Input, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import qs from 'qs';

import API from '../../../api';

class PasswordEdit extends Component {
  static propTypes = {
    location: object
  };

  handleSubmit = ({ password, passwordConfirmation }) => {
    const token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).reset_password_token;
    API.staff.updatePassword(password, passwordConfirmation, token).then(() => {
      message.success('Contraseña actualizada');
    }).catch(() => {
      message.error('Hubo un error al cambiar la contraseña');
    });
  };

  render () {
    return (
      <>
        <h1>Recuperar contraseña</h1>
        <Form layout="vertical" onFinish={this.handleSubmit} autocomplete="off" requiredMark="optional">
          <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'La contraseña no puede estar vacía.' }]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item
            label="Confirmar contraseña"
            dependencies={['password']}
            name="passwordConfirmation"
            rules={[
              {
                required: true,
                message: 'La contraseña no puede estar vacía.'
              },
              ({ getFieldValue }) => ({
                validator (rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('La contraseña ingresada no coincide.'));
                }
              })
            ]}
          >
            <Input.Password/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cambiar contraseña
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default withRouter(PasswordEdit);
