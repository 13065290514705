import React, { Component } from 'react';
import SupportAppHeader from '../app-header';
import SupportSiderMenu from '../sider-menu';
import SupportNavBar from '../nav-bar';
import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import { node } from 'prop-types';

const { Header, Content, Sider } = Layout;

class SupportLayout extends Component {
  static propTypes = {
    routes: node
  };

  render () {
    const { routes } = this.props;
    return (
      <>
        <Sider theme="light" width={230} className="site-layout-background">
          <Layout className="h-100">
            <Header>
              <SupportAppHeader/>
            </Header>
            <Content className="side-bar">
              <SupportSiderMenu/>
            </Content>
          </Layout>
        </Sider>
        <Layout>
          <Header style={{ zIndex: 98 }}>
            <SupportNavBar/>
          </Header>
          <Content className="overflow-auto px-5 py-2">
            <Switch>
              {routes}
            </Switch>
          </Content>
        </Layout>
      </>
    );
  }
}

export default SupportLayout;
