const up = (_state) => {
  const { session } = _state;
  return {
    ..._state,
    session: {
      ...session,
      currentAdmin: null
    }
  };
};

const down = (_state) => {
  return {};
};

export default { up, down };
