export const userAgentIsMobile = () => {
  const userAgent = navigator.userAgent;
  return !!((userAgent.match(/Android/i)) ||
    (userAgent.match(/webOS/i)) ||
    (userAgent.match(/iPhone/i)) ||
    (userAgent.match(/iPod/i)) ||
    (userAgent.match(/iPad/i)) ||
    (userAgent.match(/BlackBerry/i)
    ));
};
