import React, { Component } from 'react';
import { array } from 'prop-types';
import { Table, Button, Modal } from 'antd';

import API from '../../../api';
import { promiseLoader, refreshAll } from '../../common/promise-loader';
import { history } from '../../common/custom-router';
import deleteIcon from '../../../assets/images/sales-processes-delete.svg';

class ProcessTypesList extends Component {
  static propTypes = {
    processTypes: array
  };

  onDelete = (record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar este tipo de proceso?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        await API.staff.deleteProcessType(record.id);
        refreshAll();
      }
    });
  };

  render () {
    const { processTypes } = this.props;
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Acciones',
        key: 'actions',
        render: (_, record) => (
          <Button type="link" onClick={this.onDelete(record)}>
            <img src={deleteIcon} alt="delete icon"/>
          </Button>
        )
      }
    ];
    return (
      <div>
        <Table
          locale={{ emptyText: 'Sin postventas' }}
          showSorterTooltip={false}
          dataSource={processTypes}
          columns={columns}
          onRow={(record, rowIndex) => ({
            onClick: (event) => history.push(`/staff/process_types/${record.id}/edit`)
          })}
        />
      </div>
    );
  }
}

export default promiseLoader(
  () => API.staff.getProcessTypes(),
  'processTypes'
)(ProcessTypesList);
