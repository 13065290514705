import { resetSession } from '../store/actions/session';
import { history } from '../components/common/custom-router';

export class Support {
  constructor (api) {
    this.api = api;
  }

  delete = async (url, params) => {
    try {
      return await this.api.delete(url, params);
    } catch (e) {
      const { response: { status } } = e;
      if (status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/support/login');
      } else { throw e; }
    }
  };

  get = async (url, params) => {
    try {
      return await this.api.get(url, params);
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/support/login');
      } else { throw e; }
    }
  };

  post = async (url, params, extra = {}) => {
    try {
      return await this.api.post(url, params, extra);
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/support/login');
      } else { throw e; }
    }
  };

  put = async (url, params, extra = {}) => {
    try {
      return await this.api.put(url, params, extra);
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/support/login');
      } else { throw e; }
    }
  };

  patch = async (url, params, extra = {}) => {
    try {
      return await this.api.patch(url, params, extra);
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/support/login');
      } else { throw e; }
    }
  };

  createCompany = (company) => this.post('/api/support/companies', { company }).then(({ data }) => data);

  createSession = (email, password) => this.post('/api/support/sessions', { email, password });

  createUser = (companyId, user) => this.post(`/api/support/companies/${companyId}/users`, { user }).then(({ data }) => data);

  getCompanies = () => this.get('/api/support/companies').then(({ data }) => data);

  getCompany = (id) => this.get(`/api/support/companies/${id}`).then(({ data }) => data);

  getUser = (id) => this.get(`/api/support/users/${id}`).then(({ data }) => data);

  deleteCompany = (id) => this.delete(`/api/support/companies/${id}`).then(({ data }) => data);

  updateCompany = (id, company) => this.patch(`/api/support/companies/${id}`, { company }).then(({ data }) => data);
}
