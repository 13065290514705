import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { func } from 'prop-types';
import { Form, Input, Button, Typography } from 'antd';

import { history } from '../../common/custom-router';
import { supportLogin } from '../../../store/actions/session';

const { Text } = Typography;

class LoginForm extends Component {
  static propTypes = {
    login: func
  };

  state = {
    error: false
  };

  handleSubmit = ({ email, password }) => {
    this.props.login(email, password).then(() => {
      this.setState({ error: false });
      history.push('/support/companies');
    }).catch(() => {
      this.setState({ error: true });
    });
  };

  render () {
    const { error } = this.state;
    return (
      <Form onFinish={this.handleSubmit} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autoComplete="off">
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Por favor ingrese su email.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Por favor ingrese su contraseña.' }]}>
          <Input.Password/>
        </Form.Item>
        {
          error &&
          <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
            <Text type="danger">
              Email o contraseña inválidos
            </Text>
          </Form.Item>
        }
        <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
        <Link to="/staff/user/password/new">
          ¿Ha olvidado su contraseña?
        </Link>
      </Form>
    );
  }
}

const mapDispatchToProps = {
  login: supportLogin
};

export default connect(null, mapDispatchToProps)(LoginForm);
