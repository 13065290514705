import React, { Component } from 'react';
import { promiseLoader, refreshAll } from '../../common/promise-loader';
import {
  Row, Col, Form, Input, Select, Upload, Button, Table, Checkbox,
  message, Modal
} from 'antd';
import API from '../../../api';
import compose from 'lodash/fp/compose';
import { flatten } from 'lodash';
import { withRouter } from 'react-router-dom';
import { array, object } from 'prop-types';
import EditAttachmentButton from '../edit-attachment-button';
import deleteIcon from '../../../assets/images/sales-processes-delete.svg';

const { Option } = Select;
const { TextArea } = Input;

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

class SaleProcessAttachments extends Component {
  static propTypes = {
    attachmentKinds: array,
    saleProcess: object
  };

  state = {
    sellerVisible: true,
    buyerVisible: true,
    file: null
  };

  form = React.createRef();

  createAttachment = ({ stageId, attachmentKindId, description }) => {
    const { sellerVisible, buyerVisible, file } = this.state;
    const attachment = {
      attachmentKindId,
      description,
      sellerVisible,
      buyerVisible,
      file
    };
    API.staff.createAttachment(stageId, attachment).then(() => {
      this.setState({
        file: null,
        sellerVisible: true,
        buyerVisible: true
      });
      this.form.resetFields();
      refreshAll();
      message.success('Anexo subido');
    });
  };

  beforeUpload = (file) => {
    this.setState({ file });
    return false;
  };

  onRemove = (file) => {
    this.setState({ file: null });
  };

  render () {
    const { attachmentKinds, saleProcess } = this.props;
    const { file, sellerVisible, buyerVisible } = this.state;
    const attachmentColumns = [
      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'Tipo',
        dataIndex: ['attachmentKind', 'name'],
        key: 'attachmentKindName'
      },
      {
        title: 'Etapa',
        dataIndex: 'stage',
        key: 'attachmentKindName'
      },
      {
        title: 'Visible vendedor',
        key: 'sellerVisible',
        render: (_text, { sellerVisible }) => sellerVisible ? 'Sí' : 'No'
      },
      {
        title: 'Visible comprador',
        key: 'buyerVisible',
        render: (_text, { buyerVisible }) => buyerVisible ? 'Sí' : 'No'
      },
      {
        title: 'Acciones',
        key: 'actions',
        render: (_, attachment) => (
          <>
            <EditAttachmentButton attachment={attachment}
              stages={saleProcess.stages}
              attachmentKinds={attachmentKinds}
            />
            <a className="mr-2" href={attachment.downloadUrl} download>
              Descargar
            </a>
            <Button type="danger" onClick={() => {
              Modal.confirm({
                title: '¿Estás seguro que deseas eliminar este archivo anexo?',
                content: 'Esta acción no se puede deshacer.',
                onOk: async () => {
                  await API.staff.deleteAttachment(attachment.id);
                  message.success('Anexo eliminado correctamente.');
                  refreshAll();
                }
              });
            }}>
              <img src={deleteIcon} alt="delete icon"/>
            </Button>
          </>
        )
      }
    ];

    const attachments = flatten(
      saleProcess.stages.map(
        ({ name, attachments }) => attachments.map(
          (attachment) => ({ ...attachment, stage: name })
        )
      )
    );

    return (
      <Row>
        <Col span={24}>
          <Form layout="vertical" onFinish={this.createAttachment} ref={(ref) => { this.form = ref; }} autocomplete="off">
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="Tipo" name="attachmentKindId">
                  <Select>
                    {
                      attachmentKinds.map(
                        ({ id, name }) => (
                          <Option key={`attachment-kind-${id}`} value={id}>
                            {name}
                          </Option>
                        )
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Etapa" name="stageId">
                  <Select>
                    {
                      saleProcess.stages.map(
                        ({ id, name }) => (
                          <Option key={`stage-${id}`} value={id}>
                            {name}
                          </Option>
                        )
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Descripción" name="description">
                  <TextArea/>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Visible vendedor">
                  <Checkbox checked={sellerVisible} onChange={({ target: { checked: visible } }) => this.setState({ sellerVisible: visible })}/>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Visible comprador">
                  <Checkbox checked={buyerVisible} onChange={({ target: { checked: visible } }) => this.setState({ buyerVisible: visible })}/>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Archivo" name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}>
                  <Upload beforeUpload={this.beforeUpload} onRemove={this.onRemove} fileList={ file ? [file] : []}>
                    <Button>
                      Elegir archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button type="primary" htmlType="submit">
                  Subir
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24} className="mt-4">
          <Table locale={{ emptyText: 'Sin anexos' }} showSorterTooltip={false} columns={attachmentColumns} dataSource={attachments} />
        </Col>
      </Row>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getAttachmentKinds();
    },
    'attachmentKinds'
  )
)(SaleProcessAttachments);
