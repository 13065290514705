import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import {
  HomeOutlined,
  TagsOutlined
} from '@ant-design/icons';

import './index.scss';

const { SubMenu } = Menu;

class SiderMenu extends Component {
  static propTypes = {
    admin: object,
    location: object
  };

  state = {
    mainMenuKeys: [],
    settingsMenuKeys: []
  };

  onMainMenuSelected = ({ key }) => {
    this.setState({ mainMenuKeys: [key], settingsMenuKeys: [] });
  };

  render () {
    const { admin } = this.props;
    const { mainMenuKeys } = this.state;
    return (
      <div className="d-flex flex-column justify-content-between h-100 pb-4">
        <Menu theme="light" mode="inline" openKeys={['post-sale']} selectedKeys={mainMenuKeys} onSelect={this.onMainMenuSelected}>
          {
            admin && (
              <SubMenu
                key="post-sale"
                title={
                  <span>
                    <HomeOutlined/>
                    <span>Admin</span>
                  </span>
                }
              >
                <Menu.Item key="ps-1">
                  <Link to="/support/companies">
                    <TagsOutlined/>
                    <span>
                      Empresas
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            )
          }
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.session.currentAdmin
});

export default connect(mapStateToProps)(withRouter(SiderMenu));
