import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import { Button, Descriptions } from 'antd';

const { Item } = Descriptions;

class MetaStage extends Component {
  static propTypes = {
    metaStage: object
  };

  render () {
    const { metaStage: { id, name, minDays, maxDays, days, description, responsible } } = this.props;
    return (
      <>
        <Descriptions title={name} size="small" columns={3} layout="vertical" bordered className="mb-2">
          <Item label="Nombre" span={3}>{name}</Item>
          <Item label="Duración">{days}</Item>
          <Item label="Días mínimos">{minDays}</Item>
          <Item label="Días máximos">{maxDays}</Item>
          <Item label="Responsable" span={3}>{responsible}</Item>

          <Item label="Descripción" span={3}>{description}</Item>
        </Descriptions>
        <Link to={`/staff/meta_stages/${id}/edit`}>
          <Button>
            Editar
          </Button>
        </Link>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getMetaStage(id);
    },
    'metaStage'
  )
)(MetaStage);
