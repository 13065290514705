import React, { Component } from 'react';
import ProcessTypesList from '../../../components/staff/process-types-list';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

class ProcessTypesIndex extends Component {
  render () {
    return (
      <>
        <h3>Tipos de proceso</h3>
        <Link to="/staff/process_types/create">
          <Button className="mb-2">
            Crear proceso
          </Button>
        </Link>
        <Link to="/staff/meta_stages">
          <Button className="mb-2">
            Administrar etapas guardadas
          </Button>
        </Link>
        <ProcessTypesList/>
      </>
    );
  }
}

export default ProcessTypesIndex;
