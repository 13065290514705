import React, { Component } from 'react';
import { array, bool, string } from 'prop-types';
import { Table, Input, Row, Col, Modal, Button, Dropdown, Menu } from 'antd';
import { SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DaysUntilBadge from '../days-until-badge';

import API from '../../../api';
import { promiseLoader, refreshAll } from '../../common/promise-loader';
import { history } from '../../common/custom-router';
import { parseDate } from '../../../utils/dates';
import { localeCompareSorter, numberCompareSorter } from '../../../utils/sorters';
import { includesFilter } from '../../../utils/filters';

import addIcon from '../../../assets/images/sales-processes-add.png';
import dateIcon from '../../../assets/images/date-icon.svg';
import deleteIcon from '../../../assets/images/sales-processes-delete.svg';
import directionIcon from '../../../assets/images/direction-icon.svg';
import stageIcon from '../../../assets/images/stage-icon.svg';
import userIcon from '../../../assets/images/user-icon.svg';

class SaleProcessesList extends Component {
  static propTypes = {
    saleProcesses: array,
    currentUserRole: string,
    ended: bool
  };

  state = {
    filter: ''
  };

  onDelete = (record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar esta postventa?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        await API.staff.deleteSaleProcess(record.id);
        refreshAll();
      }
    });
  };

  columnTittle = (icon, tittle) => {
    return (
      <div className='text-strong font-weight-bold'>
        { icon && <img className='mr-2' src={icon}/>}
        {tittle}
      </div>
    );
  };

  tableColumns = () => {
    const { ended } = this.props;
    let baseColumns = [
      {
        title: (this.columnTittle(null, 'ID')),
        dataIndex: 'id',
        sorter: numberCompareSorter(({ id }) => id)
      },
      {
        title: (this.columnTittle(directionIcon, 'Dirección')),
        dataIndex: 'name',
        sorter: localeCompareSorter(({ name }) => name)
      },
      {
        title: (this.columnTittle(userIcon, 'Vendedor')),
        key: 'sellerFirstName',
        sorter: localeCompareSorter(({ seller: { firstName, lastName } }) => firstName + lastName),
        render: (_, { seller: { firstName, lastName } }) => (
          <React.Fragment key={`${firstName}-${lastName}`}>
            {`${firstName} ${lastName}`}
          </React.Fragment>)
      },
      {
        title: (this.columnTittle(userIcon, 'Comprador')),
        sorter: localeCompareSorter(({ buyer: { firstName, lastName } }) => firstName + lastName),
        render: (_, { buyer: { firstName, lastName } }) => (
          <React.Fragment key={`${firstName}-${lastName}`}>
            {`${firstName} ${lastName}`}
          </React.Fragment>
        )
      },
      {
        title: (this.columnTittle(dateIcon, 'Tags')),
        dataIndex: 'tags',
        sorter: localeCompareSorter(({ tags }) => tags),
        render: (_text, { tags }) => tags?.join(', ') || '-'
      }
    ];

    if (ended) {
      baseColumns = [
        ...baseColumns,
        {
          title: (this.columnTittle(dateIcon, 'Fecha de término')),
          sorter: localeCompareSorter(({ endDate }) => endDate),
          render: (_text, { endDate }) => endDate && parseDate(endDate)
        }
      ];
    } else {
      baseColumns = [
        ...baseColumns,
        {
          title: (this.columnTittle(stageIcon, 'Etapa')),
          dataIndex: 'currentStage',
          sorter: localeCompareSorter(({ currentStage }) => currentStage)
        },
        {
          title: (this.columnTittle(dateIcon, 'Fecha inicio')),
          sorter: localeCompareSorter(({ startDate }) => startDate),
          render: (_text, { startDate }) => startDate && parseDate(startDate)
        },
        {
          title: (this.columnTittle(null, 'Estado')),
          sorter: numberCompareSorter(({ daysUntilStageEnd }) => daysUntilStageEnd),
          render: (_text, { daysUntilStageEnd }) => <DaysUntilBadge daysUntil={daysUntilStageEnd}/>
        }
      ];
    }

    return [
      ...baseColumns,
      {
        title: (this.columnTittle(null, 'Seguimiento')),
        key: 'clients',
        render: (_, record) => (
          <>
            <Link to={`/sellers/sale_processes/${record.sellerPublicId}`} target="_blank" rel="noopener noreferrer" className="mr-2">
              <Button onClick={(e) => { e.stopPropagation(); }}>
                Vendedor
              </Button>
            </Link>
            <Link to={`/buyers/sale_processes/${record.buyerPublicId}`} target="_blank" rel="noopener noreferrer" >
              <Button onClick={(e) => { e.stopPropagation(); }}>
                Comprador
              </Button>
            </Link>
          </>
        )
      },
      {
        title: '',
        key: 'actions',
        render: (_, record) => (
          <>
            <Button type="link" onClick={this.onDelete(record)}>
              <img src={deleteIcon} alt="delete icon"/>
            </Button>
          </>
        )
      }
    ];
  };

  render () {
    const { saleProcesses, ended, currentUserRole } = this.props;
    const { filter } = this.state;

    let records;

    records = saleProcesses.map((saleProcess) => {
      return {
        tags: [],
        buyer: { firstName: 'Sin', lastName: 'Comprador' },
        seller: { firstName: 'Sin', lastName: 'Vendedor' },
        ...saleProcess
      };
    });

    if (ended) {
      records = records.filter(({ ended }) => ended);
    } else {
      records = records.filter(({ ended }) => !ended);
    }

    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to="/staff/process_types">
            Tipos de proceso
          </Link>
        </Menu.Item>

      </Menu>
    );

    if (filter) {
      records = records.filter(includesFilter(filter, [
        'name',
        'tags',
        ({ seller: { firstName } }) => firstName,
        ({ seller: { lastName } }) => lastName,
        ({ buyer: { firstName } }) => firstName,
        ({ buyer: { lastName } }) => lastName,
        'currentStage',
        ({ startDate }) => startDate ? parseDate(startDate) : ''
      ], true));
    }
    return (
      <div style={{ padding: '30px' }}>
        <Row gutter={16} justify="center" align="middle" style={{ paddingBottom: '30px' }}>
          <Col>
            <Input placeholder='Buscar propiedad...' addonAfter={<SearchOutlined/>} value={filter} onChange={({ target: { value } }) => this.setState({ filter: value })} style={{ width: '348px' }}/>
          </Col>
          <Col>
            <Link to="/staff/sale_processes/create">
              <Button type="primary">
                <img src={addIcon} alt="add" style={{ width: '20px', paddingRight: '3px', paddingBottom: '4px' }}/>
                Crear nueva
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={16} justify="start" align="start" style={{ paddingBottom: '30px' }}>
          <Col>
            <Button type="dashed" onClick={async () => {
              const { path } = await API.staff.getSaleProcessesWorkbook();
              window.open(path, '_blank');
            }}>
              Descargar
            </Button>
          </Col>
        </Row>
        <Table
          locale={{ emptyText: 'Sin postventas' }}
          showSorterTooltip={false}
          dataSource={records}
          columns={this.tableColumns()}
          rowClassName="cursor-pointer"
          pagination={{ position: ['bottomCenter'] }}
          onRow={(record, rowIndex) => ({
            onClick: (event) => history.push(`/staff/sale_processes/${record.id}`)
          })}
        />

        {currentUserRole === 'admin' && (
          <div className='d-flex flex-row-reverse mr-5 mb-5'>
            <Dropdown overlay={menu}>
              <Button className='text-strong'>
                <SettingOutlined/> Configuración
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserRole: state.session.currentUser && state.session.currentUser.role
});

export default promiseLoader(
  () => API.staff.getSaleProcesses(),
  'saleProcesses'
)(connect(mapStateToProps)(SaleProcessesList));
