import React, { Component } from 'react';
import { Alert, Button, Form, Input, Select } from 'antd';
import { string, object } from 'prop-types';
import API from '../../../api';
import { history } from '../../common/custom-router';

const { Option } = Select;

class UserForm extends Component {
  static propTypes = {
    action: string,
    user: object
  };

  state = {
    error: null
  };

  handleSubmit = (user) => {
    const { action } = this.props;
    this.setState({ error: null });
    let promise;
    if (action === 'edit') {
      const { user: { id } } = this.props;
      promise = API.staff.updateUser(id, user).then(() => {
        history.push(`/staff/users/${id}`);
      });
    }
    promise.catch((err) => {
      this.setState({ error: err.message });
    });
  };

  getInitialValues = () => {
    const { user } = this.props;
    if (user) {
      return user;
    } else {
      return {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        role: 'admin',
        email: ''
      };
    }
  };

  render () {
    const { error } = this.state;

    return (
      <>
        {
          error &&
          <Alert
            message="Error al editar usuario"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }
        <Form onFinish={this.handleSubmit} initialValues={this.getInitialValues()} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autocomplete="off">
          <Form.Item label="Nombre" name="firstName" rules={[{ required: true, message: 'El nombre no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Apellido" name="lastName" rules={[{ required: true, message: 'El apellido no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'El email no puede estar vacío.' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Teléfono" name="phoneNumber">
            <Input/>
          </Form.Item>
          <Form.Item label="Rol" name="role">
            <Select>
              <Option value="admin">
                Supervisor
              </Option>
              <Option value="basic">
                Básico
              </Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default UserForm;
