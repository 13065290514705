import React, { Component } from 'react';
import SaleProcessesList from '../../../components/staff/sale-processes-list';

class SaleProcessesIndex extends Component {
  render () {
    return (
      <>
        <SaleProcessesList/>
      </>
    );
  }
}

export default SaleProcessesIndex;
