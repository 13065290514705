import React, { Component } from 'react';
import MetaStagesList from '../../../components/staff/meta-stages-list';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

class MetaStagesIndex extends Component {
  render () {
    return (
      <>
        <h3>Administrar Etapas</h3>
        <Link to="/staff/meta_stages/create">
          <Button className="mb-2">
            Crear etapa
          </Button>
        </Link>
        <MetaStagesList/>
      </>
    );
  }
}

export default MetaStagesIndex;
