import React, { Component } from 'react';
import { Button, Cascader, Col, Form, Input, InputNumber, Row, Select, Divider, Tooltip, Checkbox } from 'antd';
import { COMMUNES_OPTIONS, COMPLEMENT_OPTIONS } from '../../../constants/address.js.erb';
import { PlusOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { array, func, string } from 'prop-types';
import { cascadeFilter } from '../../../utils/filters';
import { promiseLoader } from '../../common/promise-loader';
import API from '../../../api';
import { connect } from 'react-redux';

const { Option } = Select;

class PropertyAttributes extends Component {
  static propTypes = {
    processTypes: array,
    users: array,
    action: string,
    currentUserRole: string,
    onProcessTypeChange: func
  };

  processTypeChanged = (value) => {
    const { onProcessTypeChange } = this.props;
    onProcessTypeChange(value);
  };

  render () {
    const { processTypes, action, users, currentUserRole } = this.props;
    return (
      <>
        <Col span={24}>
          <Divider orientation="left">Datos propiedad</Divider>
        </Col>
        <Form.Item label="ID" name={['addressAttributes', 'id']} hidden>
          <Input/>
        </Form.Item>
        <Col span={6}>
          <Form.Item
            label="Ejecutivo"
            name="supervisorId" rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Select size="default" onChange={this.processTypeChanged} disabled={currentUserRole !== 'admin'}>
              {
                users.map(({ id, firstName, lastName }) => (
                  <Option value={id} key={`supervisor-option-${id}`}>
                    {firstName} {lastName}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Calle" name={['addressAttributes', 'street']} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Número" name={['addressAttributes', 'number']} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <InputNumber className="w-100"/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Región/Comuna" name={['addressAttributes', 'regionCommune']} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Cascader options={COMMUNES_OPTIONS} showSearch={{ filter: cascadeFilter }} autoComplete="new-street-address" placeholder="Seleccione región y comuna"/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Tipo de complemento" name={['addressAttributes', 'complementType']} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Select>
              {
                COMPLEMENT_OPTIONS.map((complementOption) => (
                  <Option value={complementOption} key={complementOption}>
                    {complementOption}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Complemento" name={['addressAttributes', 'complement']}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Línea adicional" name={['addressAttributes', 'extraLine']}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Valor" name='value' rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Input addonAfter={<Form.Item name='currency' rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]} noStyle>
              <Select>
                <Option value="uf">
                  UF
                </Option>
                <Option value="clp">
                  CLP
                </Option>
              </Select>
            </Form.Item>}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <>
                Tipo de proceso
                <Tooltip className="ml-2" title="Este campo no puede ser editado una vez creado el proceso.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            name="processTypeId" rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
            <Select size="default" onChange={this.processTypeChanged} disabled={action !== 'create'}>
              {
                processTypes.map(({ id, name }) => (
                  <Option value={id} key={`process-type-option-${id}`}>
                    {name}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Foja" name='sheet'>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Número" name='number'>
            <InputNumber className="w-100"/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Año" name='year'>
            <InputNumber className="w-100"/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Tags" name={['tags'] }>
            <Select mode="tags" placeholder="Ingrese tags"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Observaciones" name='description'>
            <Input.TextArea/>
          </Form.Item>
        </Col>
        <Form.List name="rolesAttributes" className="mt-1">
          {(rolesAttributes, { add, remove, move }) => {
            return (
              <Col span={24}>
                {rolesAttributes.map((field, index) => (
                  <Row key={`role-attribute-${index}`} gutter={4}>
                    <Col span={6}>
                      <Form.Item name={[index, 'number']} label="Rol" rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
                        <Input autoComplete="new-role-number"/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={[index, 'description']} label={'Descripción'} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={6} className="d-flex flex-row align-items-center">
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)}/>
                    </Col>
                  </Row>
                ))}
                <Button type="dashed" onClick={() => add()} className="mb-1">
                  <PlusOutlined /> Agregar rol
                </Button>
              </Col>
            );
          }}
        </Form.List>
        <Form.List name="additionalSupervisorsAttributes" className="mt-1">
          {(additionalSupervisorsAttributes, { add, remove, move }) => {
            return (
              <Col span={24}>
                {additionalSupervisorsAttributes.map((field, index) => (
                  <Row key={`additional-supervisor-attribute-${index}`} gutter={4}>
                    <Col span={12}>
                      <Form.Item
                        label="Ejecutivo"
                        name={[index, 'userId']} rules={[{ required: true, message: 'Este campo no puede estar vacío.' }]}>
                        <Select size="default" disabled={currentUserRole !== 'admin'}>
                          {
                            users.map(({ id, firstName, lastName }) => (
                              <Option value={id} key={`supervisor-option-${id}`}>
                                {firstName} {lastName}
                              </Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name={[index, 'hidden']} label="Oculto" valuePropName="checked">
                        <Checkbox/>
                      </Form.Item>
                    </Col>
                    <Col span={6} className="d-flex flex-row align-items-center">
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)}/>
                    </Col>
                  </Row>
                ))}
                <Button type="dashed" onClick={() => add()} className="mb-1">
                  <PlusOutlined /> Agregar supervisor
                </Button>
              </Col>
            );
          }}
        </Form.List>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserRole: state.session.currentUser.role
});

export default promiseLoader(
  () => API.staff.getUsers(),
  'users'
)(connect(mapStateToProps)(PropertyAttributes));
