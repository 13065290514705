import React, { Component } from 'react';
import { Checkbox, Form, Button, Card, message, Input, Switch, Divider, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import compose from 'lodash/fp/compose';

import './index.scss';

import API from '../../../api';
import { promiseLoader, refreshAll } from '../../../components/common/promise-loader';
import { connect } from 'react-redux';
import { number, object } from 'prop-types';

export class StaffMailerIndex extends Component {
    static propTypes = {
      company: object,
      companyId: number
    };

    state = {
      switchEvents: this.props.company.setting.dailyMail
    };

    onChangeSwitch = () => {
      this.setState({
        switchEvents: !this.state.switchEvents
      });
    };

    handleSubmit = (attributes) => {
      const { companyId } = this.props;
      const checked = attributes.checked;
      const updateMailDays = attributes.updateMailDays;
      const settingAttributes = {
        ...attributes,
        addCommentMail: this.state.switchEvents ? false : checked.includes('¿Enviar mail de notificación de comentario agregado?'),
        addAttachmentMail: this.state.switchEvents ? false : checked.includes('¿Enviar mail de notificación de documento agregado?'),
        startStageMail: this.state.switchEvents ? false : checked.includes('¿Enviar mail de notificación de inicio de etapa?'),
        dailyMail: this.state.switchEvents,
        updateMailDays: this.state.switchEvents ? updateMailDays : []
      };
      API.staff.updateCompany(companyId, { settingAttributes }).then(() => {
        refreshAll();
        message.success('Configuración actualizada');
      }).catch((e) => {
        message.error('Error');
      });
    };

    getInitialValues = () => {
      const { company: { setting } } = this.props;
      const checkedOptions = [];
      if (setting) {
        if (setting.addCommentMail) {
          checkedOptions.push('¿Enviar mail de notificación de comentario agregado?');
        }
        if (setting.addAttachmentMail) {
          checkedOptions.push('¿Enviar mail de notificación de documento agregado?');
        }
        if (setting.startStageMail) {
          checkedOptions.push('¿Enviar mail de notificación de inicio de etapa?');
        }
      }
      return {
        checked: checkedOptions,
        ccoMail: setting.ccoMail,
        updateMailDays: setting.updateMailDays
      };
    };

    render () {
      const options = [
        '¿Enviar mail de notificación de inicio de etapa?',
        '¿Enviar mail de notificación de comentario agregado?',
        '¿Enviar mail de notificación de documento agregado?'
      ];

      const days = [
        { label: 'Lunes', value: 1 },
        { label: 'Martes', value: 2 },
        { label: 'Miércoles', value: 3 },
        { label: 'Jueves', value: 4 },
        { label: 'Viernes', value: 5 },
        { label: 'Sábado', value: 6 },
        { label: 'Domingo', value: 0 }
      ];

      const inactiveColor = 'rgba(0, 0, 0, 0.25)';

      const activeColor = 'rgba(0, 0, 0, 0.65)';

      return (
        <Card className='text-left p-3 h-100 mailer-edit--container'>
          <h2 className='text-center mt-3'>E-mail</h2>
          <div className='grid-container switch-container'>
            <div>
              <Tooltip title='Elija evento por el cual desea recibir novedades sobre su proceso de postventa' color={this.state.switchEvents ? activeColor : '#73b680'}>
                <QuestionCircleOutlined style={{ color: this.state.switchEvents ? inactiveColor : '#73b680' }}/>
              </Tooltip>
              <strong style={{ marginLeft: '10px', color: this.state.switchEvents ? inactiveColor : activeColor }}>
                Seleccione por Evento
              </strong>
            </div>
            <div>
              <Switch onChange={this.onChangeSwitch} defaultChecked={this.state.switchEvents} style={{ backgroundColor: '#73b680' }}/>
            </div>
            <div>
              <Tooltip title='Elija los días de semana que desea recibir novedades sobre su proceso de postventa' color={!this.state.switchEvents ? activeColor : '#73b680'}>
                <QuestionCircleOutlined style={{ color: !this.state.switchEvents ? inactiveColor : '#73b680' }}/>
              </Tooltip>
              <strong style={{ marginLeft: '10px', color: !this.state.switchEvents ? inactiveColor : activeColor }}>
                Seleccione días para mail de novedades
              </strong>
            </div>
          </div>
          <br />
          <Form name='email-setings' onFinish={this.handleSubmit} initialValues={this.getInitialValues()}>
            <div className="grid-container checkbox-container">
              <Form.Item name='checked'>
                <Checkbox.Group options={options} disabled={this.state.switchEvents} />
              </Form.Item>
              <div className='checkbox-groups-divider'>
                <Divider type='vertical' style={{ height: '200px', backgroundColor: inactiveColor }}/>
              </div>
              <Form.Item name='updateMailDays'>
                <Checkbox.Group className="update-mail-days" options={days} disabled={!this.state.switchEvents}/>
              </Form.Item>
            </div>
            <br />
            <Form.Item name='ccoMail' label={
              <div>
                <Tooltip title='Enviar copia oculta a otros destinatarios' color={'#73b680'}>
                  <QuestionCircleOutlined style={{ color: '#73b680' }} />
                </Tooltip>
                <span style={{ marginLeft: '10px' }}>CCO Email</span>
              </div>
            }>
              <Input/>
            </Form.Item>
            <Form.Item className='text-right'>
              <Button type="primary" htmlType="submit">
                Confirmar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      );
    }
}

const mapStateToProps = (state) => ({
  companyId: state.session.currentUser && state.session.currentUser.companyId
});

export default compose(
  connect(mapStateToProps),
  promiseLoader(
    ({ companyId }) => {
      if (companyId) {
        return API.staff.getCompany(companyId);
      } else {
        return null;
      }
    },
    'company', true
  )
)(StaffMailerIndex);
