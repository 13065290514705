// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import esES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './application';
import App from '../components/app';
import { store, persistor } from '../store';
import API from '../api';

import '../styles/antd-theme/index.less';
import '../styles/index.scss';

if (window.location.hash === '#purge') {
  persistor.purge();
}

API.setStore(store);

document.addEventListener('DOMContentLoaded', () => {
  const appRoot = document.createElement('div');
  appRoot.setAttribute('class', 'app');
  document.body.appendChild(appRoot);
  ReactDOM.render(
    <ConfigProvider locale={esES}>
      <Provider store={store}>
        <PersistGate loading="Cargando..." persistor={persistor}>
          <App/>
        </PersistGate>
      </Provider>
    </ConfigProvider>,
    appRoot
  );
});
