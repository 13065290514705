import React, { Component } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import {
  Link
} from 'react-router-dom';

import { resetSession } from '../../../store/actions/session';
import { history } from '../../common/custom-router';
import { func, object } from 'prop-types';

const { SubMenu } = Menu;

class NavBar extends Component {
  static propTypes = {
    logout: func,
    admin: object
  };

  logout = () => {
    const { logout } = this.props;
    logout();
    history.push('/support/login');
  };

  render () {
    const { admin } = this.props;

    return <Menu
      theme="light"
      mode="horizontal"
      selectable={false}
      className="nav-bar d-flex flex-row-reverse"
    >
      {
        admin && <SubMenu title={<><UserOutlined/>{admin.email} <CaretDownOutlined/></>}>
          <Menu.Item>
            <Link to={`/support/admins/${admin.id}`}>
              Ver admin
            </Link>
          </Menu.Item>
          <Menu.Item onClick={this.logout}>
            Cerrar sesión
          </Menu.Item>
        </SubMenu>
      }
      {
        !admin && <Menu.Item key="login" align="right">
          <Link to="/support/login">
            Iniciar sesión
          </Link>
        </Menu.Item>
      }
    </Menu>;
  }
}

const mapStateToProps = (state) => ({
  admin: state.session.currentAdmin
});

const mapDispatchToProps = {
  logout: resetSession
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
