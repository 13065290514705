import React, { Component } from 'react';
import { Alert, Button, Form, Input, InputNumber } from 'antd';
import { string, object } from 'prop-types';
import API from '../../../api';
import { history } from '../../common/custom-router';
import { Link } from 'react-router-dom';

class MetaStageForm extends Component {
  static propTypes = {
    action: string,
    metaStage: object
  };

  state = {
    error: null
  };

  handleSubmit = (metaStage) => {
    const { action } = this.props;
    this.setState({ error: null });
    let promise;
    if (action === 'create') {
      promise = API.staff.createMetaStage(metaStage).then(({ id }) => {
        history.push(`/staff/meta_stages/${id}`);
      });
    } else {
      const { metaStage: { id } } = this.props;
      promise = API.staff.updateMetaStage(id, metaStage).then(() => {
        history.push(`/staff/meta_stages/${id}`);
      });
    }
    promise.catch((err) => {
      this.setState({ error: err.message });
    });
  };

  getInitialValues = () => {
    const { metaStage } = this.props;
    if (metaStage) {
      return metaStage;
    } else {
      return {
        name: '',
        description: '',
        days: 1,
        maxDays: 1,
        minDays: 1
      };
    }
  };

  render () {
    const { action, metaStage } = this.props;
    const { error } = this.state;

    return (
      <>
        {
          error &&
          <Alert
            message="Error al editar etapa"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }
        <Form onFinish={this.handleSubmit} initialValues={this.getInitialValues()} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autocomplete="off">
          <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'El nombre no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Descripción" name="description" rules={[{ required: true, message: 'La descripción no puede estar vacía.' }]}>
            <Input.TextArea/>
          </Form.Item>
          <Form.Item label="Responsable" name="responsible" rules={[{ required: true, message: 'El responsable no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Duración" name="days" rules={[{ required: true, message: 'La duración no puede estar vacía.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item label="Días mínimos" name="minDays" rules={[{ required: true, message: 'Los días mínimos no pueden estar vacíos.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item label="Días máximos" name="maxDays" rules={[{ required: true, message: 'Los días máximos no pueden estar vacíos.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
            <Link to={action === 'create' ? '/staff/meta_stages' : `/staff/meta_stages/${metaStage.id}`}>
              <Button type="secondary" className="mr-2">
                Cancelar
              </Button>
            </Link>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default MetaStageForm;
