import { isFunction, deburr } from 'lodash';

export const includesFilter = (key, attributeNamesOrFunctions, ignoreCase = false) => (record) => {
  let found = false;
  attributeNamesOrFunctions.forEach((item) => {
    let attribute;

    if (isFunction(item)) {
      attribute = item(record);
    } else {
      attribute = record[item];
    }

    if (!attribute) {
      return;
    }

    attribute = deburr(attribute);

    if (ignoreCase) {
      found = found || attribute.toLowerCase().includes(deburr(key).toLowerCase());
    } else {
      found = found || attribute.includes(deburr(key));
    }
  });
  return found;
};

export const cascadeFilter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
