import React, { Component } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { node, object, string, bool, array } from 'prop-types';

import './index.scss';

import controPvLogo from '../../../assets/images/logo.svg';

import respIcon from '../../../assets/images/icons/client-resp-icon.svg';
import StaffAppFooter from '../../staff/app-footer';

const { Header, Content } = Layout;

class ClientLayout extends Component {
  static propTypes = {
    children: node,
    company: object,
    title: string,
    supervisor: object,
    additionalSupervisors: array,
    isMobile: bool
  };

  render () {
    const { children, title, supervisor, additionalSupervisors, company, isMobile } = this.props;
    const { name: companyName, logoUrl } = company;
    return (
      <>
        <Layout className={isMobile ? ('') : ('h-100')}>
          <Header style={{ height: 'auto' }}>
            <Row align='middle' wrap='true'>
              <Col span={7}>
                { logoUrl ? (<img src={logoUrl} alt={companyName} style={{ height: '48px' }} className="my-1"/>) : (<img src={controPvLogo} alt="control propiedades" style={{ height: '48px' }} className="my-1"/>) }
              </Col>
              <Col className='text-center' span={10}>
                <h2 className='text-strong client-header-tittle'>{title}</h2>
              </Col>
              <Col span={7} className="d-flex flex-row-reverse">
                {
                  additionalSupervisors.map((supervisor) => (
                    <Card key={`supervisor-${supervisor.id}`} className='client-header-resp' style={{ boxShadow: 'none', border: 'none' }}>
                      <img src={respIcon} alt="responsable"/> <br/>
                      <strong>Ejecutivo: </strong>{supervisor.firstName} {supervisor.lastName} <br/>
                      {supervisor.email} <br/>
                      {
                        supervisor.phoneNumber && (
                          <a href={`https://wa.me/${supervisor.phoneNumber.replace('+', '')}`}>{supervisor.phoneNumber}</a>
                        )
                      }
                    </Card>
                  ))
                }
                <Card className='client-header-resp' style={{ boxShadow: 'none', border: 'none' }}>
                  <img src={respIcon} alt="responsable"/> <br/>
                  <strong>Ejecutivo: </strong>{supervisor.firstName} {supervisor.lastName} <br/>
                  {supervisor.email} <br/>
                  {
                    supervisor.phoneNumber && (
                      <a href={`https://wa.me/${supervisor.phoneNumber.replace('+', '')}`}>{supervisor.phoneNumber}</a>
                    )
                  }
                </Card>
              </Col>
            </Row>
          </Header>
          <Content className="overflow-auto client-layout">
            {children}
          </Content>
          <StaffAppFooter company={company}/>
        </Layout>
      </>
    );
  }
}

export default ClientLayout;
