import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import SaleProcessForm from '../../../components/staff/sale-process-form';

class SaleProcessEdit extends Component {
  static propTypes = {
    saleProcess: object
  };

  render () {
    const { saleProcess } = this.props;
    return (
      <>
        <h2>Editar {saleProcess.name}</h2>
        <SaleProcessForm action="edit" saleProcess={saleProcess}/>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getSaleProcess(id);
    },
    'saleProcess'
  )
)(SaleProcessEdit);
