import Home from '../views/home';

import SellersSaleProcess from '../views/sellers/sale-processes/sale-process';

import BuyersSaleProcess from '../views/buyers/sale-processes/sale-process';

import StaffSaleProcessesIndex from '../views/staff/sale-processes/sale-processes-index';
import StaffSaleProcess from '../views/staff/sale-processes/sale-process';
import StaffSaleProcessCreate from '../views/staff/sale-processes/sale-process-create';
import StaffSaleProcessEdit from '../views/staff/sale-processes/sale-process-edit';
import StaffProcessTypesIndex from '../views/staff/process-types/process-types-index';
import StaffProcessType from '../views/staff/process-types/process-type';
import StaffMetaStagesIndex from '../views/staff/meta-stages/meta-stages-index';
import StaffMetaStage from '../views/staff/meta-stages/meta-stage';
import StaffMetaStageEdit from '../views/staff/meta-stages/meta-stage-edit';
import StaffMetaStageCreate from '../views/staff/meta-stages/meta-stage-create';
import StaffProcessTypeCreate from '../views/staff/process-types/process-type-create';
import StaffProcessTypeEdit from '../views/staff/process-types/process-type-edit';
import StaffCompanyEdit from '../views/staff/company/company-edit';
import StaffEndedSaleProcesses from '../views/staff/sale-processes/ended-sale-processes';
import StaffPasswordNew from '../views/staff/user/password-new';
import StaffPasswordEdit from '../views/staff/user/password-edit';
import StaffUser from '../views/staff/users/user';
import StaffUserEdit from '../views/staff/users/user-edit';
import StaffUserCreate from '../views/staff/users/user-create';
import StaffLogin from '../views/staff/login';
import StaffUsers from '../views/staff/users';

import SupportLogin from '../views/support/login';
import SupportCompaniesIndex from '../views/support/companies/companies-index';
import SupportCompany from '../views/support/companies/company';
import SupportCompanyCreate from '../views/support/companies/company-create';
import SupportCompanyEdit from '../views/support/companies/company-edit';
import SupportUserCreate from '../views/support/users/user-create';

export default {
  '/': {
    component: Home
  },
  '/buyers': {
    routes: {
      '/sale_processes': {
        routes: {
          '/:id': {
            component: BuyersSaleProcess
          }
        }
      }
    }
  },
  '/sellers': {
    routes: {
      '/sale_processes': {
        routes: {
          '/:id': {
            component: SellersSaleProcess
          }
        }
      }
    }
  },
  '/staff': {
    routes: {
      '/users': {
        component: StaffUsers,
        routes: {
          '/new': {
            component: StaffUserCreate
          },
          '/:id': {
            component: StaffUser,
            routes: {
              '/edit': {
                component: StaffUserEdit
              }
            }
          }
        }
      },
      '/login': {
        component: StaffLogin
      },
      '/sale_processes': {
        component: StaffSaleProcessesIndex,
        routes: {
          '/create': {
            component: StaffSaleProcessCreate
          },
          '/:id': {
            component: StaffSaleProcess,
            routes: {
              '/edit': {
                component: StaffSaleProcessEdit
              }
            }
          }
        }
      },
      '/process_types': {
        component: StaffProcessTypesIndex,
        routes: {
          '/create': {
            component: StaffProcessTypeCreate
          },
          '/:id': {
            component: StaffProcessType,
            routes: {
              '/edit': {
                component: StaffProcessTypeEdit
              }
            }
          }
        }
      },
      '/meta_stages': {
        component: StaffMetaStagesIndex,
        routes: {
          '/create': {
            component: StaffMetaStageCreate
          },
          '/:id': {
            component: StaffMetaStage,
            routes: {
              '/edit': {
                component: StaffMetaStageEdit
              }
            }
          }
        }
      },
      '/user': {
        routes: {
          '/password': {
            routes: {
              '/new': {
                component: StaffPasswordNew
              },
              '/edit': {
                component: StaffPasswordEdit
              }
            }
          }
        }
      },
      '/ended_sale_processes': {
        component: StaffEndedSaleProcesses
      },
      '/company': {
        routes: {
          '/edit': {
            component: StaffCompanyEdit
          }
        }
      }
    }
  },
  '/support': {
    routes: {
      '/login': {
        component: SupportLogin
      },
      '/companies': {
        component: SupportCompaniesIndex,
        routes: {
          '/create': {
            component: SupportCompanyCreate
          },
          '/:id': {
            component: SupportCompany,
            routes: {
              '/edit': {
                component: SupportCompanyEdit
              },
              '/users': {
                routes: {
                  '/new': {
                    component: SupportUserCreate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
