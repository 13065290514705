import React, { Component } from 'react';
import { object, array } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import { orderBy, flatten } from 'lodash';
import moment from 'moment';
import { Table, Tabs, Space, Tooltip, Button } from 'antd';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import StatusBadge from '../../../components/common/status-badge';
import EditStageButton from '../../../components/staff/edit-stage-button';
import SaleProcessAttachments from '../../../components/staff/sale-process-attachments';
import CommentForm from '../../../components/staff/comment-form';
import { parseDate } from '../../../utils/dates';
import { localeCompareSorter } from '../../../utils/sorters';
import EditCommentButton from '../../../components/staff/edit-comment-button';
import DeleteCommentButton from '../../../components/staff/delete-comment-button';
import SaleProcessShow from '../../../components/staff/sale-process-show';

const { TabPane } = Tabs;

class SaleProcess extends Component {
  static propTypes = {
    saleProcesses: array,
    saleProcess: object
  };

  getStepStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'wait';
      case 'finished':
        return 'finish';
      case 'cancelled':
        return 'error';
      case 'ongoing':
        return 'process';
    }
  };

  renderComments = () => {
    const { saleProcess: { stages } } = this.props;
    const comments = flatten(stages.map((stage) => stage.comments.map((comment) => ({ ...comment, stage: stage.name, stageId: stage.id }))));
    return (
      <>
        <CommentForm stages={stages}/>
        <Table locale={{ emptyText: 'Sin comentarios' }} showSorterTooltip={false} dataSource={comments} columns={[
          {
            title: 'Fecha',
            key: 'createdAt',
            sorter: localeCompareSorter(({ createdAt }) => moment.utc(createdAt).local().format('DD/MM/YYYY HH:mm')),
            render: (_text, { createdAt }) => (
              <Tooltip title={moment.utc(createdAt).fromNow()}>
                {moment.utc(createdAt).local().format('DD/MM/YYYY HH:mm')}
              </Tooltip>
            )
          },
          {
            title: 'Usuario',
            key: ['author', 'email'],
            sorter: localeCompareSorter(({ author: { email } }) => email),
            render: (_text, { author: { email } }) => (
              <>
                {email}
              </>
            )
          },
          {
            title: 'Visible vendedor',
            key: 'sellerVisible',
            render: (_text, { sellerVisible }) => sellerVisible ? 'Sí ' : 'No'
          },
          {
            title: 'Visible comprador',
            key: 'buyerVisible',
            render: (_text, { buyerVisible }) => buyerVisible ? 'Sí' : 'No'
          },
          {
            title: 'Comentario',
            dataIndex: 'content',
            key: 'content'
          },
          {
            title: 'Etapa',
            dataIndex: 'stage',
            key: 'stage'
          },
          {
            title: 'Acciones',
            key: 'actions',
            render: (_text, comment) => (<Space size="middle">
              <EditCommentButton comment={comment}/>
              <DeleteCommentButton comment={comment}/>
            </Space>)
          }
        ]}/>
      </>
    );
  };

  render () {
    const { saleProcess, saleProcesses } = this.props;
    const { name, stages, sellerPublicId, buyerPublicId } = saleProcess;

    const saleProcessIndex = saleProcesses.findIndex(({ id }) => id === saleProcess.id);
    let nextSaleProcessId;
    let prevSaleProcessId;
    if (saleProcessIndex < saleProcesses.length - 1) {
      nextSaleProcessId = saleProcesses[saleProcessIndex + 1].id;
    }
    if (saleProcessIndex > 0) {
      prevSaleProcessId = saleProcesses[saleProcessIndex - 1].id;
    }
    return (
      <>
        <div className="d-flex flex-row justify-content-between">
          <h1>{name}</h1>
          <div>
            {prevSaleProcessId && <Link to={`/staff/sale_processes/${prevSaleProcessId}`}>
              <Button type="default">
                {'<'}
              </Button>
            </Link>
            }
            {nextSaleProcessId &&
            <Link to={`/staff/sale_processes/${nextSaleProcessId}`}>
              <Button type="default">
                {'>'}
              </Button>
            </Link>
            }
          </div>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Datos generales" key="1">
            <SaleProcessShow saleProcess={saleProcess}/>
          </TabPane>
          <TabPane tab="Seguimiento" key="2">
            <div className="d-flex justify-content-end mb-3">
              <Link to={`/sellers/sale_processes/${sellerPublicId}`} target="_blank" rel="noopener noreferrer" class="mr-2">
                <Button type="primary">
                  Ver seguimiento vendedor
                </Button>
              </Link>
              <Link to={`/buyers/sale_processes/${buyerPublicId}`} target="_blank" rel="noopener noreferrer">
                <Button type="primary">
                  Ver seguimiento comprador
                </Button>
              </Link>
            </div>
            <Table locale={{ emptyText: 'Sin etapas' }} showSorterTooltip={false} dataSource={orderBy(stages, 'position')} columns={[
              {
                title: 'Etapa',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Fecha Inicio',
                dataIndex: 'startDate',
                render: (_text, { startDate }) => startDate && parseDate(startDate)
              },
              {
                title: 'Fecha pronosticada',
                key: 'estimatedEndDate',
                render: (_text, { estimatedEndDate }) => estimatedEndDate && parseDate(estimatedEndDate)
              },
              {
                title: 'Fecha de término',
                key: 'endDate',
                render: (_text, { endDate }) => endDate && parseDate(endDate)
              },
              {
                title: 'Responsable',
                dataIndex: 'responsible',
                key: 'responsible'
              },
              {
                title: 'Estado',
                key: 'status',
                render: (_text, { status }) => (<StatusBadge status={status}/>)
              },
              {
                title: 'Acciones',
                key: 'actions',
                render: (_text, stage) => (<Space size="middle">
                  <EditStageButton stage={stage}/>
                </Space>)
              }
            ]}/>
          </TabPane>
          <TabPane tab="Bitácora" key="4">
            {this.renderComments()}
          </TabPane>
          <TabPane tab="Anexos" key="5">
            <SaleProcessAttachments saleProcess={saleProcess}/>
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    () => {
      return API.staff.getSaleProcesses();
    },
    'saleProcesses'
  ),
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getSaleProcess(id);
    },
    'saleProcess', true
  )
)(SaleProcess);
