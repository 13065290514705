import React, { Component } from 'react';
import Loading from '../loading';
import Error from '../error';
import { isEqual } from 'lodash';

const refs = [];

export const refreshAll = () => {
  refs.forEach((component) => {
    component.executePromise();
  });
};

export const promiseLoader = (getPromise, name = 'result', listenToChange = false) => (WrappedComponent) => {
  return class WrappedWithLoader extends Component {
    state = {
      result: null,
      loading: true,
      error: null
    };

    componentDidMount () {
      refs.push(this);
      this.executePromise();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      if (!isEqual(prevProps, this.props) && listenToChange) {
        this.executePromise();
      }
    }

    componentWillUnmount () {
      refs.splice(refs.indexOf(this), 1);
    }

    executePromise = () => {
      const promise = getPromise(this.props);
      if (promise) {
        promise.then((result) => this.setState({ result }))
          .catch((error) => this.setState({ error }))
          .finally(() => this.setState({ loading: false }));
      } else {
        this.setState({ loading: false, error: false, result: null });
      }
    };

    render () {
      const { loading, error, result } = this.state;
      if (loading) return <Loading/>;
      if (error) return <Error error={error}/>;
      return (
        <WrappedComponent {...{ [name]: result }} {...this.props}/>
      );
    }
  };
};
