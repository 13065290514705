import React, { Component } from 'react';
import { Row, Col, Typography } from 'antd';
import LoginForm from '../../components/support/login-form';

const { Text } = Typography;

class Login extends Component {
  render () {
    return (
      <>
        <Row>
          <Col span={12} offset={6} className="d-flex justify-content-center mb-2">
            <h1>Iniciar sesión <small><Text type="secondary">Admin</Text></small></h1>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={6} >
            <LoginForm/>
          </Col>
        </Row>
      </>
    );
  }
}

export default Login;
