import React, { Component } from 'react';
import { Card, Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { string } from 'prop-types';

import './index.scss';

import icon1 from '../../../assets/images/icons/binnacle-icon1.svg';
import icon2 from '../../../assets/images/icons/binnacle-icon2.svg';
import icon3 from '../../../assets/images/icons/binnacle-icon3.svg';

class CardBinnacle extends Component {
    static propTypes = {
      date: string,
      authorEmail: string,
      comment: string
    };

    render () {
      const { date, authorEmail, comment } = this.props;
      return (
        <Card className='card-binnacle' style={{ marginTop: '20px' }}>
          <Row className='text-left'>
            <Col span={9}>
              <img src={icon1} alt="icon1"/>
              <strong className='text-strong'> Fecha </strong>
            </Col>
            <Col span={15}>
              <p>
                <Tooltip title={moment.utc(date).fromNow()}>
                  {moment.utc(date).local().format('DD/MM/YYYY HH:mm')}
                </Tooltip>
              </p>
            </Col>

            <Col span={9}>
              <img src={icon2} alt="icon2"/>
              <strong className='text-strong'> Autor </strong>
            </Col>
            <Col span={15}>
              <p>{authorEmail}</p>
            </Col>

            <Col span={9}>
              <img src={icon3} alt="icon3"/>
              <strong className='text-strong'> Comentario </strong>
            </Col>
            <Col span={15}>
              {comment ? (
                <p>{comment}</p>
              ) : (
                <p>Sin comentario</p>
              )}
            </Col>

          </Row>
        </Card>

      );
    }
}

export default CardBinnacle;
