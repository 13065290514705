import React, { Component } from 'react';
import { Layout, Col, Row } from 'antd';
import { object } from 'prop-types';

import linkedinLogo from '../../../assets/images/footer-linkeding-logo.svg';
import instagramLogo from '../../../assets/images/Instagram.svg';
import facebookLogo from '../../../assets/images/f-logo-white.png';
import webpageIcon from '../../../assets/images/webpage-icon.png';

const { Footer } = Layout;

class StaffAppFooter extends Component {
  static propTypes = {
    company: object
  };

  render () {
    const { company: { instagramUrl, webpageUrl, facebookUrl, linkedinUrl } } = this.props;
    return (
      <Footer className='text-invert' align="middle" justify="center">
        <Row gutter='30' justify='center'>
          <Col>
            <p>Siguenos en nuestras redes sociales</p>
          </Col>
          {
            linkedinUrl && (
              <Col>
                <a href={`https://linkedin.com/in/${linkedinUrl}`}>
                  <img src={linkedinLogo} alt="logo linkedin" height="25px"/>
                </a>
              </Col>
            )
          }
          {
            instagramUrl && (
              <Col>
                <a href={`https://instagram.com/${instagramUrl}`}>
                  <img src={instagramLogo} alt="logo instagram" height="25px"/>
                </a>
              </Col>
            )
          }
          {
            facebookUrl && (
              <Col>
                <a href={`https://facebook.com/${facebookUrl}`}>
                  <img src={facebookLogo} alt="logo instagram" height="25px"/>
                </a>
              </Col>
            )
          }
          {
            webpageUrl && (
              <Col>
                <a href={`https://${webpageUrl}`}>
                  <img src={webpageIcon} alt="logo webpage" height="25px"/>
                </a>
              </Col>
            )
          }
        </Row>
      </Footer>
    );
  }
}

export default StaffAppFooter;
