import React, { Component } from 'react';
import { Alert, Button, Form, Input, InputNumber, message, Modal } from 'antd';
import { refreshAll } from '../../common/promise-loader';

import { string, object, func, bool } from 'prop-types';
import API from '../../../api';

class MetaStageFormModal extends Component {
  static propTypes = {
    action: string,
    metaStage: object,
    onStageSave: func,
    visible: bool
  };

  state = {
    error: null
  };

  handleSubmit = (metaStage) => {
    const { action } = this.props;
    const { onStageSave } = this.props;
    this.setState({ error: null });
    let promise;
    if (action === 'create') {
      promise = API.staff.createMetaStage(metaStage).then(({ id }) => {
        message.success('Nueva etapa agregada con exito', 5);
        onStageSave(id);
        refreshAll();
      });
    } else {
      const { metaStage: { id } } = this.props;
      promise = API.staff.updateMetaStage(id, metaStage).then(() => {
        message.success('Nueva etapa agregada con exito', 5);
        onStageSave(id);
        refreshAll();
      });
    }
    promise.catch((err) => {
      message.error('Error al crear una  etapa', 5);
      this.setState({ error: err.message });
      onStageSave(null);
    });
  };

  getInitialValues = () => {
    const { metaStage } = this.props;
    if (metaStage) {
      return metaStage;
    } else {
      return {
        name: '',
        description: '',
        days: 1,
        maxDays: 1,
        minDays: 1
      };
    }
  };

  render () {
    const { error } = this.state;
    const { visible, onStageSave } = this.props;
    return (
      <Modal
        onCancel={() => onStageSave(false)}
        title='Crear etapa'
        visible={visible}
        footer={[
          <Button key='cancel' onClick={() => onStageSave(false)}>Cancelar</Button>,
          <Button form='createStageForm' key='submit' htmlType='submit' type='primary'>Guardar</Button>
        ]}
      >
        {
          error &&
          <Alert
            message="Error al editar etapa"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }
        <Form id='createStageForm' onFinish={this.handleSubmit} initialValues={this.getInitialValues()} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autocomplete="off">
          <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'El nombre no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Descripción" name="description" rules={[{ required: true, message: 'La descripción no puede estar vacía.' }]}>
            <Input.TextArea/>
          </Form.Item>
          <Form.Item label="Responsable" name="responsible" rules={[{ required: true, message: 'El responsable no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Duración" name="days" rules={[{ required: true, message: 'La duración no puede estar vacía.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item label="Días mínimos" name="minDays" rules={[{ required: true, message: 'Los días mínimos no pueden estar vacíos.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item label="Días máximos" name="maxDays" rules={[{ required: true, message: 'Los días máximos no pueden estar vacíos.' }]}>
            <InputNumber min={0} precision={0}/>
          </Form.Item>
          <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
          </Form.Item>
        </Form>

      </Modal>
    );
  }
}

export default MetaStageFormModal;
