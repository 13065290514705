import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';

import { getRootReducer } from './reducers';
import { migrate } from './migrations';

const persistConfig = {
  key: 'root',
  version: 2,
  migrate,
  storage
};

const createReduxStore = () => {
  const persistedReducer = persistReducer(persistConfig, getRootReducer());
  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(
        thunk
      )
    )
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = createReduxStore();
