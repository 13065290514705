import React, { Component } from 'react';
import { Alert, Button, Checkbox, Form, Input, InputNumber, Row, Col, Select, List } from 'antd';
import { string, object, array } from 'prop-types';
import API from '../../../api';
import { history } from '../../common/custom-router';
import { promiseLoader } from '../../common/promise-loader';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import MetaStageFormModal from '../meta-stage-form-modal';
import { PlusCircleOutlined, MenuOutlined } from '@ant-design/icons';
import ReactDragListView from 'react-drag-listview';

const { Option } = Select;

class ProcessTypeForm extends Component {
  static propTypes = {
    action: string,
    processType: object,
    metaStages: array
  };

  form = React.createRef();

  constructor (props) {
    super(props);
    this.state = {
      error: null,
      modalCreateStageVisible: false,
      stageSelected: null
    };
    const { processType } = this.props;
    if (processType) {
      const { hasCreditInfo, hasDebtInfo } = processType;
      this.state = { ...this.state, hasDebtInfo, hasCreditInfo };
    } else {
      this.state.hasCreditInfo = false;
      this.state.hasDebtInfo = false;
    }
  }

  getInitialValues = () => {
    const { processType } = this.props;
    if (processType) {
      const { name, description, stageTemplates } = processType;
      return {
        name,
        description,
        stageTemplatesAttributes: sortBy(stageTemplates, 'position').map((stageTemplate) => ({
          ...stageTemplate,
          metaStageId: stageTemplate.metaStage.id
        }))
      };
    } else {
      return {
        name: '',
        description: '',
        stageTemplatesAttributes: []
      };
    }
  };

  handleSubmit = (fields) => {
    const { action } = this.props;
    let promise;
    if (action === 'create') {
      promise = API.staff.createProcessType(fields).then(({ id }) => {
        history.push(`/staff/process_types/${id}`);
      });
    } else {
      const { processType: { id } } = this.props;
      promise = API.staff.updateProcessType(id, this.getEditedProcessType(fields)).then(() => {
        history.push(`/staff/process_types/${id}`);
      });
    }
    promise.catch((err) => {
      this.setState({ error: err.message });
    });
  };

  getEditedProcessType = (processType) => {
    const { hasCreditInfo, hasDebtInfo } = this.state;
    const { processType: { stageTemplates: oldStageTemplatesAttributes } } = this.props;
    const { name, description, stageTemplatesAttributes } = processType;
    const finalStageTemplatesAttributes = [...stageTemplatesAttributes];

    if (oldStageTemplatesAttributes) {
      oldStageTemplatesAttributes.forEach(({ id: oldId }) => {
        if (!stageTemplatesAttributes.map(({ id }) => id).includes(oldId)) {
          finalStageTemplatesAttributes.push({ id: oldId, _destroy: true });
        }
      });
      stageTemplatesAttributes.forEach((stageTemplate, index) => {
        const oldStageTemplate = oldStageTemplatesAttributes.find(({ metaStage: { id } }) => id === stageTemplate.metaStageId);
        if (oldStageTemplate) {
          finalStageTemplatesAttributes[index] = { ...finalStageTemplatesAttributes[index], id: oldStageTemplate.id };
        }
      });
    }

    return {
      name,
      description,
      hasCreditInfo,
      hasDebtInfo,
      stageTemplatesAttributes: finalStageTemplatesAttributes.map(({ metaStageId, days, maxDays, minDays, id }, index) => {
        const stageTemplate = { position: index + 1, metaStageId, days, maxDays, minDays };
        if (id) {
          stageTemplate.id = id;
        }
        return stageTemplate;
      })
    };
  };

  showModal = (index) => {
    this.setState({ stageSelected: index });
    this.setState({ modalCreateStageVisible: true });
  }

  stageTemplateInputNumber = (index, attr, label) => {
    return (
      <Form.Item name={[index, attr]} label={label}>
        <InputNumber min={0} precision={0}/>
      </Form.Item>
    );
  };

  handleStageSave = (res) => {
    if (res) {
      this.setState({ modalCreateStageVisible: false });
    } else {
      this.setState({ modalCreateStageVisible: false });
    }
  }

  stageTemplateMetaStage = (index) => {
    const { metaStages } = this.props;
    return (
      <Form.Item name={[index, 'metaStageId']} label="Etapa" rules={[{ required: true, message: 'La etapa no puede estar vacía.' }]}>
        <Select size="default" onChange={(selectedId) => {
          const stageTemplates = this.form.getFieldValue('stageTemplatesAttributes');
          const { days, minDays, maxDays } = metaStages.find(({ id }) => selectedId === id);
          stageTemplates[index] = {
            ...stageTemplates[index],
            days,
            minDays,
            maxDays
          };
          this.form.setFieldsValue({ stageTemplates });
        }}
        dropdownRender={menu => (
          <div className='text-center'>
            {menu}
            <Button onClick={() => this.showModal(index)}>Agregar nueva</Button>
          </div>
        )}
        >
          {
            metaStages.map(({ id, name }) => (
              <Option value={id} key={`template-${index}-meta-stage-option-${id}`}>
                {name}
              </Option>
            ))
          }
        </Select>
      </Form.Item>
    );
  };

  render () {
    const { error, hasDebtInfo, hasCreditInfo } = this.state;
    return (
      <>
        {
          error &&
          <Alert
            message="Error al editar etapa"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }

        <MetaStageFormModal
          action='create'
          visible={this.state.modalCreateStageVisible}
          onStageSave={(res) => this.handleStageSave(res)}
        />

        <Form onFinish={this.handleSubmit} initialValues={this.getInitialValues()} ref={(ref) => { this.form = ref; }} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autocomplete="off">
          <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'El nombre no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Descripción" name="description" rules={[{ required: true, message: 'La descripción no puede estar vacía.' }]}>
            <Input.TextArea/>
          </Form.Item>
          <Form.Item label="Con crédito">
            <Checkbox checked={hasCreditInfo} onChange={({ target: { checked: hasCreditInfo } }) => this.setState({ hasCreditInfo })}/>
          </Form.Item>
          <Form.Item label="Con deuda">
            <Checkbox checked={hasDebtInfo} onChange={({ target: { checked: hasDebtInfo } }) => this.setState({ hasDebtInfo })}/>
          </Form.Item>

          <Form.List name="stageTemplatesAttributes">
            {(stageTemplatesAttributes, { add, remove, move }) => {
              return (
                <div>
                  <div className='d-flex justify-content-start m-5'>
                    <Button type="primary" onClick={() => add(0, 0)}>
                      <PlusCircleOutlined style={{ fontSize: '16px' }}/>
                      Agregar etapa
                    </Button>
                  </div>
                  <ReactDragListView
                    nodeSelector=".ant-list-item.draggble"
                    onDragEnd={(fromIndex, toIndex) => { move(fromIndex, toIndex); }}
                    lineClassName='drag-line'
                  >
                    <List
                      size="small"
                      bordered
                      dataSource={stageTemplatesAttributes}

                      renderItem={(field, index) => {
                        return (
                          <>
                            <List.Item
                              className="draggble"
                            >
                              <List.Item.Meta description={
                                <>
                                  <Row key={`stage-template-attribute-${index}`}>
                                    <Col span={6}>
                                      {
                                        this.stageTemplateMetaStage(index)
                                      }
                                    </Col>
                                    <Col span={4}>
                                      {
                                        this.stageTemplateInputNumber(index, 'days', 'Duración')
                                      }
                                    </Col>
                                    <Col span={4}>
                                      {
                                        this.stageTemplateInputNumber(index, 'minDays', 'Días mínimos')
                                      }
                                    </Col>
                                    <Col span={4}>
                                      {
                                        this.stageTemplateInputNumber(index, 'maxDays', 'Días máximos')
                                      }
                                    </Col>
                                    <Col span={3}>
                                      <Button onClick={() => remove(field.name)}>
                                      Eliminar
                                      </Button>
                                    </Col>
                                    <Col span={2}>
                                      <MenuOutlined />
                                    </Col>
                                  </Row>
                                </>
                              }/>
                            </List.Item>
                          </>
                        );
                      }}
                    >
                    </List>
                  </ReactDragListView>
                </div>
              );
            }}
          </Form.List>
          <Form.Item>
            <div className='d-flex justify-content-end'>
              <Link to='/staff/process_types'>
                <Button type="secondary" className="mr-2">
                    Cancelar
                </Button>
              </Link>
              <Button type="primary" htmlType="submit">
                  Guardar
              </Button>
            </div>

          </Form.Item>
        </Form>
      </>
    );
  }
}

export default promiseLoader(
  () => API.staff.getMetaStages(),
  'metaStages'
)(ProcessTypeForm);
