import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';

import enCursoLogo from '../../../assets/images/sales-processes-encurso.svg';
import finishLogo from '../../../assets/images/sales-processes-finish.svg';
import './index.scss';
import { object } from 'prop-types';

class SalesProcessesNav extends React.Component {
    static propTypes = {
      location: object
    }

    state = {
      selected: ['1']
    }

    componentDidMount = () => {
      const { location: { pathname } } = this.props;
      if (pathname.includes('/staff/ended_sale_processes')) {
        this.setState({ selected: ['2'] });
      }
    }

    handleClick = (e) => {
      this.setState({
        selected: e.key
      });
    }

    render () {
      let show = false;

      const { location: { pathname } } = this.props;

      if (pathname.includes('/staff/sale_processes') || pathname.includes('/staff/ended_sale_processes')) {
        show = true;
      }

      return (
        <>
          {
            show && (
              <div className="d-flex justify-content-center flex-row" style={{ paddingTop: '20px' }}>
                <Menu mode="horizontal" defaultSelectedKeys={['1']} selectedKeys={this.state.selected}>

                  <Menu.Item key="1" onClick={(e) => (this.handleClick(e))}>
                    <Link to="/staff/sale_processes">
                      <img className='p-2' src={enCursoLogo} alt='en curso' ></img>En Curso
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="2" onClick={(e) => (this.handleClick(e))}>
                    <Link to="/staff/ended_sale_processes" >
                      <img className='p-2' src={finishLogo} alt='finalizadas'></img>Finalizada
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            )
          }

        </>
      );
    }
}

export default withRouter(SalesProcessesNav);
