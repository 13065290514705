import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import UserForm from '../../../components/staff/user-form';

class UserEdit extends Component {
  static propTypes = {
    user: object
  };

  render () {
    const { user } = this.props;
    return (
      <>
        <h2>Editar {name}</h2>
        <UserForm action="edit" user={user}/>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getUser(id);
    },
    'user'
  )
)(UserEdit);
