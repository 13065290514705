const up = (_state) => {
  return {
    session: {
      token: null,
      currentUser: null
    }
  };
};

const down = (_state) => {
  return {};
};

export default { up, down };
