import React, { Component } from 'react';
import { Button, message, Modal } from 'antd';
import { object } from 'prop-types';
import API from '../../../api';
import { refreshAll } from '../../common/promise-loader';

import deleteIcon from '../../../assets/images/sales-processes-delete.svg';

class DeleteCommentButton extends Component {
  static propTypes = {
    comment: object
  };

  deleteComment = () => {
    const { comment: { id } } = this.props;
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar este comentario?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        await API.staff.deleteComment(id);
        message.success('Comentario eliminado');
        refreshAll();
      }
    });
  }

  render () {
    return (
      <>
        <Button type="link" onClick={this.deleteComment}>
          <img src={deleteIcon} alt="delete icon"/>
        </Button>
      </>
    );
  }
}

export default DeleteCommentButton;
