import { camelCase, snakeCase, isArray, isPlainObject } from 'lodash';

export const camelizeKeys = (obj, exceptions = []) => {
  if (isArray(obj)) {
    return obj.map(v => camelizeKeys(v, exceptions));
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [exceptions.includes(key) ? key : camelCase(key)]: camelizeKeys(obj[key], exceptions)
      }),
      {}
    );
  }
  return obj;
};

export const snakeKeys = (obj, exceptions = []) => {
  if (isArray(obj)) {
    return obj.map(v => snakeKeys(v, exceptions));
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [exceptions.includes(key) ? key : snakeCase(key)]: snakeKeys(obj[key], exceptions)
      }),
      {}
    );
  }
  return obj;
};
