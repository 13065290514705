import React, { Component } from 'react';

import UserForm from '../../../components/support/user-form';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

class UserCreate extends Component {
  static propTypes = {
    match: object
  };

  render () {
    const { match: { params: { id: companyId } } } = this.props;
    return (
      <>
        <h2>Crear usuario</h2>
        <UserForm action="create" companyId={companyId}/>
      </>
    );
  }
}

export default withRouter(UserCreate);
