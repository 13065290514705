import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import { Redirect, withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { connect } from 'react-redux';

import LoginForm from '../../components/staff/login-form';

import './login.scss';
import { object } from 'prop-types';

class Login extends Component {
  static propTypes = {
    user: object
  };

  render () {
    const { user } = this.props;
    return (
      <>
        { user && (<Redirect to='/staff/sale_processes'></Redirect>)}
        <div className='div-login justify-content-center'>
          <Row >
            <Col span={12} offset={6} className="d-flex justify-content-center mb-2">
              <img src={logo} alt="logo" style={{ marginBottom: '23px' }}/>
            </Col>
          </Row>
          <Row justify="center">
            <Card className='card-login' >
              <LoginForm/>
            </Card>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser
});

export default connect(mapStateToProps)(withRouter(Login));
