import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import { Button } from 'antd';

class User extends Component {
  static propTypes = {
    user: object
  };

  render () {
    const { user: { id, firstName, lastName, phoneNumber, email } } = this.props;
    return (
      <>
        <h2>{firstName} {lastName}</h2>
        <div className="mb-2">
          <strong>Teléfono:</strong> {phoneNumber}
        </div>
        <div className="mb-2">
          <strong>Email:</strong> {email}
        </div>
        <Link to={`/staff/users/${id}/edit`}>
          <Button>
            Editar
          </Button>
        </Link>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.staff.getUser(id);
    },
    'user'
  )
)(User);
