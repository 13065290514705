import {
  SET_USER_SESSION,
  SET_ADMIN_SESSION,
  SET_IMPERSONATION,
  RESET_IMPERSONATION,
  RESET_SESSION
} from '../constants/session';
import API from '../../api';

export const setUserSession = (token, user) => ({
  type: SET_USER_SESSION,
  payload: {
    user,
    token
  }
});

export const setAdminSession = (token, admin) => ({
  type: SET_ADMIN_SESSION,
  payload: {
    admin,
    token
  }
});

export const setImpersonation = (user) => ({
  type: SET_IMPERSONATION,
  payload: {
    user
  }
});

export const resetImpersonation = () => ({
  type: RESET_IMPERSONATION
});

export const resetSession = () => ({
  type: RESET_SESSION
});

export const staffLogin = (email, password) => async (dispatch, getState) => {
  const { data: { token, user } } = await API.staff.createSession(email, password);
  dispatch(setUserSession(token, user));
};

export const supportLogin = (email, password) => async (dispatch, getState) => {
  const { data: { token, admin } } = await API.support.createSession(email, password);
  dispatch(setAdminSession(token, admin));
};

export const impersonateUser = (userId) => async (dispatch, getState) => {
  const user = await API.support.getUser(userId);
  dispatch(setImpersonation(user));
};
