import React, { Component, createRef } from 'react';
import { Col, Form, Input, Row, Select, Button, Checkbox, message } from 'antd';
import { array } from 'prop-types';
import API from '../../../api';
import { refreshAll } from '../../common/promise-loader';

const { Option } = Select;
const { TextArea } = Input;

class CommentForm extends Component {
  static propTypes = {
    stages: array
  };

  formRef = createRef();

  state = {
    sellerVisible: true,
    buyerVisible: true
  };

  resetForm = () => {
    this.formRef.resetFields();
    this.setState({ hidden: false });
  };

  handleSubmit = ({ stageId, content }) => {
    const { sellerVisible, buyerVisible } = this.state;

    API.staff.createComment(stageId, {
      content,
      sellerVisible,
      buyerVisible
    }).then(() => {
      this.resetForm();
      message.success('Mensaje creado correctamente.');
      refreshAll();
    });
  };

  getInitialValues = () => {
    return {
      stageId: null,
      content: ''
    };
  };

  render () {
    const { stages } = this.props;
    const { sellerVisible, buyerVisible } = this.state;
    return (
      <Form onFinish={this.handleSubmit} initialValues={this.getInitialValues()} ref={(ref) => { this.formRef = ref; } } autocomplete="off">
        <Row gutter={16}>
          <Col span={14}>
            <Form.Item label="Comentario" name="content">
              <TextArea/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Etapa" name="stageId">
              <Select>
                {
                  stages.map(({ id, name }) => (
                    <Option key={`stage-${id}`} value={id}>
                      {name}
                    </Option>)
                  )
                }
              </Select>
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label="Visible vendedor">
                  <Checkbox checked={sellerVisible} onChange={({ target: { checked: visible } }) => this.setState({ sellerVisible: visible })}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Visible comprador">
                  <Checkbox checked={buyerVisible} onChange={({ target: { checked: visible } }) => this.setState({ buyerVisible: visible })}/>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button type="primary" htmlType="submit">
              Comentar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default CommentForm;
