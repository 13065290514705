import React, { Component } from 'react';

import controlPropImg from '../../../assets/images/controlprop-logo-big.png';

class AppHeader extends Component {
  render () {
    return (
      <>
        <img src={controlPropImg} alt="control propiedades" style={{ height: '45px' }} className="my-1"/>
      </>
    );
  }
}

export default AppHeader;
