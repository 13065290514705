import React, { Component } from 'react';

import SaleProcessForm from '../../../components/staff/sale-process-form';

class SaleProcessCreate extends Component {
  render () {
    return (
      <>
        <h1>Crear proceso</h1>
        <SaleProcessForm action="create"/>
      </>
    );
  }
}

export default SaleProcessCreate;
