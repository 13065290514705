import initialStateMigration from './20191212-initial-state';
import addCurrentAdminToSession from './20200119-add-current-admin-to-session';
import fixCompanyId from './20210804-fix-company-id';

const MIGRATIONS = {
  '00': initialStateMigration,
  '01': addCurrentAdminToSession,
  '02': fixCompanyId
};

export const migrate = async (state, targetVersion) => {
  const { _persist } = state || {};

  let version;
  if (_persist && _persist.version !== undefined) {
    version = parseInt(_persist.version);
  } else {
    version = -1;
  }

  let migratedState = state;

  if (targetVersion > version) {
    Object.keys(MIGRATIONS)
      .filter((key) => parseInt(key) > version && parseInt(key) <= targetVersion)
      .sort()
      .forEach((key) => {
        migratedState = MIGRATIONS[key].up(migratedState);
      });
  } else {
    Object.keys(MIGRATIONS)
      .filter((key) => parseInt(key) <= version && parseInt(key) > targetVersion)
      .sort()
      .reverse()
      .forEach((key) => {
        migratedState = MIGRATIONS[key].down(migratedState);
      });
  }
  return { ...migratedState };
};
