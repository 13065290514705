import React, { Component } from 'react';
import { Alert, Button, Form, Row, Col, message } from 'antd';
import { string, object, array, number, func } from 'prop-types';
import API from '../../../api';
import { history } from '../../common/custom-router';
import { promiseLoader, refreshAll } from '../../common/promise-loader';
import { Link } from 'react-router-dom';
import PropertyAttributes from './property-attributes';
import ClientAttributes from './client-attributes';
import DebtInfoAttributes from './debt-info-attributes';
import CreditInfoAttributes from './credit-info-attributes';
import { getErrorElement } from '../../../utils/form';
import { connect } from 'react-redux';

class SaleProcessForm extends Component {
  static propTypes = {
    action: string,
    saleProcess: object,
    processTypes: array,
    currentUserId: number,
    onEdit: func
  };

  form = React.createRef();

  state = {
    processType: null,
    error: null
  };

  constructor (props) {
    super(props);
    const { saleProcess, processTypes } = this.props;
    if (saleProcess) {
      this.state.processType = processTypes.find(({ id }) => id === saleProcess.processType.id);
    }
  }

  handleSubmit = (saleProcess) => {
    const { action, onEdit } = this.props;
    this.setState({ error: null });
    console.log(saleProcess);
    let promise;
    if (action === 'create') {
      promise = API.staff.createSaleProcess(this.getSaleProcessAttributes(saleProcess)).then(({ id }) => {
        history.push(`/staff/sale_processes/${id}`);
        message.success('Proceso creado');
      });
    } else {
      const { saleProcess: { id } } = this.props;
      promise = API.staff.updateSaleProcess(id, this.getSaleProcessAttributes(saleProcess)).then(() => {
        message.success('Proceso editado');
        onEdit();
        refreshAll();
      });
    }
    promise.catch((err) => {
      this.setState({ error: getErrorElement(err) });
    });
  };

  processTypeChanged = (value) => {
    const { processTypes } = this.props;
    this.setState({ processType: processTypes.find(({ id }) => id === value) });
  };

  getArrayAttributes = (name, attributes) => {
    const { saleProcess } = this.props;
    if (saleProcess) {
      const finalAttributes = [...attributes];

      const { [name]: oldAttributes } = saleProcess;

      console.log(oldAttributes);

      oldAttributes.forEach(({ id: oldId }) => {
        if (!attributes.map(({ id }) => id).includes(oldId)) {
          finalAttributes.push({ id: oldId, _destroy: true });
        }
      });

      return finalAttributes;
    } else {
      return attributes;
    }
  };

  getSaleProcessAttributes = (saleProcess) => {
    const { action, saleProcess: propsSaleProcess } = this.props;
    const {
      addressAttributes, rolesAttributes,
      sellerAttributes, buyerAttributes, additionalSupervisorsAttributes
    } = saleProcess;

    const { regionCommune: addressRegionCommune } = addressAttributes;
    const { regionCommune: sellerRegionCommune } = sellerAttributes;
    const { regionCommune: buyerRegionCommune } = buyerAttributes;

    const attributes = {
      ...saleProcess,
      rolesAttributes: this.getArrayAttributes('roles', rolesAttributes),
      additionalSupervisorsAttributes:
        this.getArrayAttributes('additionalSupervisors',
          additionalSupervisorsAttributes),
      addressAttributes: {
        ...addressAttributes,
        region: addressRegionCommune[0],
        commune: addressRegionCommune[1]
      }
    };

    if (action === 'create') {
      if (buyerAttributes.id) {
        attributes.buyerId = buyerAttributes.id;
        delete attributes.buyerAttributes;
      } else if (!buyerAttributes.rut) {
        delete attributes.buyerAttributes;
      } else {
        attributes.buyerAttributes = {
          ...buyerAttributes,
          region: buyerRegionCommune && buyerRegionCommune[0],
          commune: buyerRegionCommune && buyerRegionCommune[1]
        };
      }
      if (sellerAttributes.id) {
        attributes.sellerId = sellerAttributes.id;
        delete attributes.sellerAttributes;
      } else if (!sellerAttributes.rut) {
        delete attributes.sellerAttributes;
      } else {
        attributes.sellerAttributes = {
          ...sellerAttributes,
          region: sellerRegionCommune && sellerRegionCommune[0],
          commune: sellerRegionCommune && sellerRegionCommune[1]
        };
      }
    } else {
      /* eslint-disable eqeqeq */
      if (buyerAttributes.id != propsSaleProcess.buyer?.id) {
      /* eslint-enable eqeqeq */
        attributes.buyerId = buyerAttributes.id;
        delete attributes.buyerAttributes;
      } else if (!buyerAttributes.rut) {
        attributes.buyerId = buyerAttributes.id;
        delete attributes.buyerAttributes;
      } else {
        attributes.buyerAttributes = {
          ...buyerAttributes,
          region: buyerRegionCommune && buyerRegionCommune[0],
          commune: buyerRegionCommune && buyerRegionCommune[1]
        };
      }
      /* eslint-disable eqeqeq */
      if (sellerAttributes.id != propsSaleProcess.seller?.id) {
      /* eslint-enable eqeqeq */
        attributes.sellerId = sellerAttributes.id;
        delete attributes.sellerAttributes;
      } else if (!sellerAttributes.rut) {
        attributes.sellerId = sellerAttributes.id;
        delete attributes.sellerAttributes;
      } else {
        attributes.sellerAttributes = {
          ...sellerAttributes,
          region: sellerRegionCommune && sellerRegionCommune[0],
          commune: sellerRegionCommune && sellerRegionCommune[1]
        };
      }
    }
    return attributes;
  };

  getInitialValues = () => {
    const { saleProcess, currentUserId } = this.props;
    if (saleProcess) {
      const {
        name,
        description,
        tags,
        supervisorId,
        creditInfo,
        debtInfo,
        address,
        sheet,
        year,
        purpose,
        value,
        currency,
        number,
        seller,
        buyer,
        roles,
        additionalSupervisors,
        processType: { id: processTypeId }
      } = saleProcess;
      return {
        name,
        description,
        tags: tags,
        sheet,
        year,
        purpose,
        value,
        currency,
        processTypeId,
        number,
        supervisorId,
        sellerAttributes: {
          ...seller,
          regionCommune: [seller?.region, seller?.commune]
        },
        buyerAttributes: {
          ...buyer,
          regionCommune: [buyer?.region, buyer?.commune]
        },
        creditInfoAttributes: creditInfo,
        debtInfoAttributes: debtInfo,
        rolesAttributes: roles,
        additionalSupervisorsAttributes: additionalSupervisors,
        addressAttributes: {
          ...address,
          regionCommune: [address.region, address.commune]
        }
      };
    } else {
      return {
        name: '',
        description: '',
        tags: [],
        currency: 'uf',
        supervisorId: currentUserId,
        addressAttributes: {
          regionCommune: ''
        },
        additionalSupervisorsAttributes: [],
        rolesAttributes: [],
        processTypeId: null
      };
    }
  };

  renderCreditInfo = () => {
    const { processType } = this.state;
    if (processType && processType.hasCreditInfo) {
      return (
        <CreditInfoAttributes/>
      );
    } else {
      return '';
    }
  };

  renderDebtInfo = () => {
    const { processType } = this.state;
    if (processType && processType.hasDebtInfo) {
      return (
        <DebtInfoAttributes/>
      );
    } else {
      return '';
    }
  };

  render () {
    const { action, processTypes } = this.props;
    const { error } = this.state;

    return (
      <>
        {
          error &&
          <Alert
            message="Error al editar etapa"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }
        <Form layout="vertical" onFinish={this.handleSubmit} initialValues={this.getInitialValues()} ref={(ref) => { this.form = ref; }} hideRequiredMark autocomplete="off">
          <Row gutter={4}>
            <PropertyAttributes processTypes={processTypes} onProcessTypeChange={this.processTypeChanged} action={action}/>
            <ClientAttributes getForm={() => this.form} seller/>
            <ClientAttributes getForm={() => this.form} buyer/>
            {
              this.renderCreditInfo()
            }
            {
              this.renderDebtInfo()
            }
            <Col span={24}>
              <div className="d-flex flex-row justify-content-end">
                {
                  action === 'create' && (
                    <Link to='/staff/sale_processes'>
                      <Button type="secondary" className="mr-2">
                        Cancelar
                      </Button>
                    </Link>
                  )
                }
                <Button type="primary" htmlType="submit">
                  { action === 'create' ? 'Crear' : 'Guardar' }
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserId: state.session.currentUser.id
});

export default promiseLoader(
  () => API.staff.getProcessTypes(),
  'processTypes'
)(connect(mapStateToProps)(SaleProcessForm));
