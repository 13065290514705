import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import CompanyForm from '../../../components/support/company-form';

class CompanyEdit extends Component {
  static propTypes = {
    company: object
  };

  render () {
    const { company } = this.props;
    const { name } = company;
    return (
      <>
        <h2>Editar {name}</h2>
        <CompanyForm action="edit" company={company}/>
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.support.getCompany(id);
    },
    'company'
  )
)(CompanyEdit);
