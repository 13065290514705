import React, { Component } from 'react';
import { Menu, Layout } from 'antd';
import { connect } from 'react-redux';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import {
  Link
} from 'react-router-dom';

import { resetSession } from '../../../store/actions/session';
import { history } from '../../common/custom-router';
import { func, object } from 'prop-types';
import logoControlPv from '../../../assets/images/logo';
import { promiseLoader } from '../../../components/common/promise-loader';
import compose from 'lodash/fp/compose';

import API from '../../../api';

const { SubMenu } = Menu;
const { Header } = Layout;

class NavBar extends Component {
  static propTypes = {
    logout: func,
    user: object,
    company: object
  };

  logout = () => {
    const { logout } = this.props;
    logout();
    history.push('/staff/login');
  };

  render () {
    const { user } = this.props;
    const { company: { logoUrl } } = this.props;

    return (
      <>
        {
          user &&
        <Header className='h-auto p-3'>
          <div className='d-flex justify-content-around'>
            <Link to={'/'}>
              <img src={logoUrl || logoControlPv} alt="Home" style={{ height: '48px' }}/>
            </Link>
            <Menu
              theme="light"
              mode="horizontal"
              selectable={false}
              className="nav-bar d-flex flex-row-reverse"
            >
              <SubMenu title={<><UserOutlined/>{user.email} <CaretDownOutlined/></>}>
                <Menu.Item>
                  <Link to={`/staff/users/${user.id}`}>
                    {user.email}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={'/staff/users'}>
                    Usuarios
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/staff/company/edit">
                    Configuración
                  </Link>
                </Menu.Item>
                <Menu.Item onClick={this.logout}>
                  Cerrar sesión
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Header>
        }

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  companyId: state.session.currentUser && state.session.currentUser.companyId

});

const mapDispatchToProps = {
  logout: resetSession
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  promiseLoader(
    ({ companyId }) => {
      if (companyId) {
        return API.staff.getCompany(companyId);
      } else {
        return Promise.resolve({});
      }
    },
    'company', true
  )
)(NavBar);
