import React, { Component } from 'react';

import MetaStageForm from '../../../components/staff/meta-stage-form';

class MetaStageCreate extends Component {
  render () {
    return (
      <>
        <h2>Crear etapa</h2>
        <MetaStageForm action="create"/>
      </>
    );
  }
}

export default MetaStageCreate;
