import React, { Component } from 'react';

import CompanyForm from '../../../components/support/company-form';

class CompanyCreate extends Component {
  render () {
    return (
      <>
        <h2>Crear empresa</h2>
        <CompanyForm action="create"/>
      </>
    );
  }
}

export default CompanyCreate;
