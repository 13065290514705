import React, { Component } from 'react';
import { object } from 'prop-types';

class Error extends Component {
  static propTypes = {
    error: object
  };

  render () {
    const { error } = this.props;
    return (
      <div>
        {JSON.stringify(error)}
      </div>
    );
  }
}

export default Error;
