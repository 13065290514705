import { resetSession } from '../store/actions/session';
import { history } from '../components/common/custom-router';
import { mergeObjects } from '../utils/objects';
import { snakeKeys } from '../utils/cases';

export class Staff {
  constructor (api) {
    this.api = api;
  }

  getBaseHeaders = () => {
    const {
      session: { currentUser, currentAdmin }
    } = this.api.store.getState();

    const baseHeaders = {};

    if (currentUser && currentAdmin) {
      return {
        ...baseHeaders,
        'Impersonate-User-Id': `${currentUser.id}`
      };
    } else {
      return baseHeaders;
    }
  };

  baseConfig = async () => ({
    headers: await this.getBaseHeaders()
  });

  delete = async (url, params, extra) => {
    try {
      return await this.api.delete(url, params, mergeObjects(await this.baseConfig(), extra));
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/staff/login');
      } else { throw e; }
    }
  };

  get = async (url, params, extra) => {
    try {
      return await this.api.get(url, params, mergeObjects(await this.baseConfig(), extra));
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/staff/login');
      } else { throw e; }
    }
  };

  post = async (url, params, extra = {}) => {
    try {
      return await this.api.post(url, params, mergeObjects(await this.baseConfig(), extra));
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/staff/login');
      } else { throw e; }
    }
  };

  put = async (url, params, extra = {}) => {
    try {
      return await this.api.put(url, params, mergeObjects(await this.baseConfig(), extra));
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/staff/login');
      } else { throw e; }
    }
  };

  patch = async (url, params, extra = {}) => {
    try {
      return await this.api.patch(url, params, mergeObjects(await this.baseConfig(), extra));
    } catch (e) {
      if (e.response.status === 401) {
        await this.api.store.dispatch(resetSession());
        history.push('/staff/login');
      } else { throw e; }
    }
  };

  getAttachmentKinds = () => this.get('/api/staff/attachment_kinds').then(({ data }) => data);

  getClients = () => this.get('/api/staff/clients').then(({ data }) => data);

  getCompany = (id) => this.get(`/api/staff/companies/${id}`).then(({ data }) => data);

  getSaleProcesses = () => this.get('/api/staff/sale_processes').then(({ data }) => data);

  getProcessTypes = () => this.get('/api/staff/process_types').then(({ data }) => data);

  getMetaStages = () => this.get('/api/staff/meta_stages').then(({ data }) => data);

  getSaleProcess = (id) => this.get(`/api/staff/sale_processes/${id}`).then(({ data }) => data);

  getProcessType = (id) => this.get(`/api/staff/process_types/${id}`).then(({ data }) => data);

  getUser = (id) => this.get(`/api/staff/users/${id}`).then(({ data }) => data);

  getUsers = () => this.get('/api/staff/users').then(({ data }) => data);

  getMetaStage = (id) => this.get(`/api/staff/meta_stages/${id}`).then(({ data }) => data);

  createSession = (email, password) => this.post('/api/staff/sessions', { email, password });

  createUser = (user) => this.post('/api/staff/users', { user }).then(({ data }) => data);

  createMetaStage = (metaStage) => this.post('/api/staff/meta_stages', { metaStage }).then(({ data }) => data);

  createProcessType = (processType) => this.post('/api/staff/process_types', { processType }).then(({ data }) => data);

  createSaleProcess = (saleProcess) => this.post('/api/staff/sale_processes', { saleProcess }).then(({ data }) => data);

  createAttachment = (stageId, {
    description,
    file,
    attachmentKindId,
    sellerVisible,
    buyerVisible
  }) => {
    const formData = new FormData();
    formData.append('attachment[description]', description);
    formData.append('attachment[seller_visible]', sellerVisible);
    formData.append('attachment[buyer_visible]', buyerVisible);
    formData.append('attachment[file]', file);
    formData.append('attachment[attachment_kind_id]', attachmentKindId);
    return this.post(`/api/staff/stages/${stageId}/attachments`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);
  };

  createComment = (stageId, comment) => this.post(`/api/staff/stages/${stageId}/comments`, { comment }).then(({ data }) => data);

  deleteSaleProcess = (id) => this.delete(`/api/staff/sale_processes/${id}`).then(({ data }) => data);

  deleteProcessType = (id) => this.delete(`/api/staff/process_types/${id}`).then(({ data }) => data);

  deleteMetaStage = (id) => this.delete(`/api/staff/meta_stages/${id}`).then(({ data }) => data);

  deleteComment = (id) => this.delete(`/api/staff/comments/${id}`).then(({ data }) => data);

  deleteAttachment = (id) => this.delete(`/api/staff/attachments/${id}`).then(({ data }) => data);

  deleteUser = (id) => this.delete(`/api/staff/users/${id}`).then(({ data }) => data);

  updateMetaStage = (id, metaStage) => this.patch(`/api/staff/meta_stages/${id}`, { metaStage }).then(({ data }) => data);

  updateProcessType = (id, processType) => this.patch(`/api/staff/process_types/${id}`, { processType }).then(({ data }) => data);

  updateSaleProcess = (id, saleProcess) => this.patch(`/api/staff/sale_processes/${id}`, { saleProcess }).then(({ data }) => data);

  updateComment = (id, comment) => this.patch(`/api/staff/comments/${id}`, { comment }).then(({ data }) => data);

  updateAttachment = (id, attachment) => this.patch(`/api/staff/attachments/${id}`, { attachment }).then(({ data }) => data);

  updateUser = (id, user) => this.patch(`/api/staff/users/${id}`, { user }).then(({ data }) => data);

  updateStage = (id, stage) => this.patch(`/api/staff/stages/${id}`, { stage }).then(({ data }) => data);

  updateCompanyMultipart = (id, company) => {
    const formData = new FormData();
    const logo = company.logo;
    if (logo) {
      formData.append('company[logo]', logo);
    }
    const snakeCompany = snakeKeys(company);
    Object.keys(snakeCompany).forEach((key) => {
      if (key === 'logo') return;
      formData.append(`company[${key}]`, snakeCompany[key]);
    });
    return this.patch(`/api/staff/companies/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);
  };

  updateCompany = (id, company) => this.patch(`/api/staff/companies/${id}`, { company }).then(({ data }) => data);

  createPassword = (email) => this.post('/api/staff/user/password', { user: { email } }).then(({ data }) => data);

  updatePassword = (password, passwordConfirmation, resetPasswordToken) => this.put('/api/staff/user/password', { user: { password, passwordConfirmation, resetPasswordToken } }).then(({ data }) => data);

  getSaleProcessesWorkbook = () => this.get('/api/staff/workbooks/sale_processes').then(({ data }) => data);
}
