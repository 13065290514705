import {
  SET_USER_SESSION,
  RESET_SESSION,
  SET_ADMIN_SESSION,
  SET_IMPERSONATION,
  RESET_IMPERSONATION
} from '../constants/session';

export const initialState = {
  token: null,
  currentUser: null,
  currentAdmin: null
};

export const sessionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_SESSION: {
      return { ...initialState };
    }
    case SET_IMPERSONATION: {
      const { user } = payload;
      return { ...state, currentUser: user };
    }
    case RESET_IMPERSONATION: {
      return { ...state, currentUser: null };
    }
    case SET_ADMIN_SESSION: {
      const { admin, token } = payload;
      return { ...state, token, currentUser: null, currentAdmin: admin };
    }
    case SET_USER_SESSION: {
      const { user, token } = payload;
      return { ...state, token, currentUser: user, currentAdmin: null };
    }
    default:
      return state;
  }
};
