import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import NewUserForm from '../../../components/staff/new-user-form';

class UserCreate extends Component {
  static propTypes = {
    user: object
  };

  render () {
    const { user } = this.props;
    return (
      <>
        <h2>Crear usuario</h2>
        <NewUserForm action="create" user={user}/>
      </>
    );
  }
}

export default withRouter(UserCreate);
