import React, { Component } from 'react';
import { number } from 'prop-types';

class DaysUntilBadge extends Component {
  static propTypes = {
    daysUntil: number
  };

  getColor = () => {
    const { daysUntil } = this.props;
    if (daysUntil === 0) {
      return '#f5222d';
    } else if (daysUntil <= 2) {
      return '#fadb14';
    } else {
      return '#73B680';
    }
  };

  render () {
    return (
      <div style={{ height: '30px', borderRadius: '1rem', backgroundColor: this.getColor() }}>

      </div>
    );
  }
}

export default DaysUntilBadge;
