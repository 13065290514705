import React, { Component } from 'react';
import StaffNavBar from '../../../components/staff/nav-bar';
import ImpersonationPanel from '../../../components/staff/impersonation-panel';
import SalesProcessesNav from '../../../components/staff/sales-processes-nav';
import StaffAppFooter from '../../../components/staff/app-footer';
import compose from 'lodash/fp/compose';
import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import { node, object } from 'prop-types';
import { promiseLoader } from '../../common/promise-loader';
import { connect } from 'react-redux';
import API from '../../../api';

const { Content } = Layout;

class StaffLayout extends Component {
  static propTypes = {
    routes: node,
    company: object
  };

  render () {
    const { routes, company } = this.props;
    return (
      <>
        <Layout>
          <StaffNavBar/>
          <Content className="overflow-auto px-5 py-2">
            <ImpersonationPanel/>
            <SalesProcessesNav/>
            <Switch>
              {routes}
            </Switch>
          </Content>
        </Layout>
        <StaffAppFooter company={company}/>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.session.currentUser?.companyId
});

export default compose(
  connect(mapStateToProps, null),
  promiseLoader(
    ({ companyId }) => {
      if (companyId) {
        return API.staff.getCompany(companyId);
      } else {
        return Promise.resolve({});
      }
    },
    'company', true
  )
)(StaffLayout);
