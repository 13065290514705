import React, { Component, createRef } from 'react';
import { Button, Modal, Form, Row, Col, DatePicker, Select, message, Tooltip } from 'antd';
import { object } from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { refreshAll } from '../../common/promise-loader';

import API from '../../../api';
import { getStatusText, STATUSES } from '../../../models/stages';
import editIcon from '../../../assets/images/edit-icon.svg';
import './index.scss';

const { Option } = Select;

class EditStageButton extends Component {
  static propTypes = {
    stage: object
  };

  formRef = createRef();

  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  handleOk = () => {
    this.formRef.submit();
  };

  handleCancel = () => {
    this.closeModal();
  };

  stageParams = ({ status, estimatedEndDate, startDate, endDate }) => {
    const params = { status, estimatedEndDate, startDate, endDate };

    if (startDate && startDate.isValid()) {
      params.startDate = startDate.format('YYYY-MM-DD');
    }

    if (endDate && endDate.isValid()) {
      params.endDate = endDate.format('YYYY-MM-DD');
    }

    if (estimatedEndDate && estimatedEndDate.isValid()) {
      params.estimatedEndDate = estimatedEndDate.format('YYYY-MM-DD');
    }
    return params;
  };

  handleSubmit = (attributes) => {
    const { stage: { id } } = this.props;
    API.staff.updateStage(id, this.stageParams(attributes)).then(() => {
      refreshAll();
      this.closeModal();
      message.success('Etapa editada');
    });
  };

  getInitialValues = () => {
    const { stage: { status, estimatedEndDate, endDate, startDate } } = this.props;
    return {
      status,
      estimatedEndDate: estimatedEndDate && moment(estimatedEndDate, 'YYYY/MM/DD'),
      endDate: endDate && moment(endDate, 'YYYY/MM/DD'),
      startDate: startDate && moment(startDate, 'YYYY/MM/DD')
    };
  };

  render () {
    const { stage: { name } } = this.props;
    return (
      <>
        <Button type="link" onClick={this.showModal}>
          <img src={editIcon} alt="edit icon"/>
        </Button>
        <Modal
          title={`Editar ${name}`}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form layout="vertical" className="stage-form" initialValues={this.getInitialValues()} ref={(ref) => { this.formRef = ref; }} onFinish={this.handleSubmit} autocomplete="off">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Estado" name="status">
                  <Select>
                    {
                      STATUSES.map((status) => (
                        <Option key={status} value={status}>
                          {getStatusText(status)}
                        </Option>)
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      Fecha de inicio
                      <Tooltip className="ml-2" title="Este campo indica la fecha en la que inició esta etapa.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  dependencies={['status']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator (rule, value) {
                        if (getFieldValue('status') === 'pending') {
                          return Promise.resolve();
                        } else {
                          if (value) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(new Error('Este campo no puede estar vacío.'));
                          }
                        }
                      }
                    })
                  ]}
                  name="startDate"
                >
                  <DatePicker format="DD/MM/YYYY" allowClear={false} showToday={false}
                    onChange={(date) => {
                      if (date) {
                        const status = this.formRef.getFieldValue('status');
                        if (status === 'pending') {
                          this.formRef.setFieldsValue({ status: 'ongoing' });
                        }
                      }
                    }}
                    renderExtraFooter={(_mode) => (
                      <div className="text-center">
                        <Button type="link" onClick={() => {
                          const status = this.formRef.getFieldValue('status');
                          if (status !== 'pending') {
                            this.formRef.setFieldsValue({ startDate: null, status: 'pending' });
                          } else {
                            this.formRef.setFieldsValue({ startDate: null });
                          }
                        }}>
                          Borrar
                        </Button>
                      </div>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true, message: 'La fecha esperada no puede estar vacía.' }]}
                  label={
                    <>
                      Fecha esperada de término
                      <Tooltip className="ml-2" title="Esta es la fecha en la que se espera se termine la etapa. Se calcula automáticamente en base a la duración de la etapa, por lo que solo debe ser modificada en casos especiales.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  name="estimatedEndDate">
                  <DatePicker format="DD/MM/YYYY" allowClear={false} showToday={false}
                    renderExtraFooter={(_mode) => (
                      <div className="text-center">
                        <Button type="link" onClick={() => this.formRef.setFieldsValue({ estimatedEndDate: null })}>
                          Borrar
                        </Button>
                      </div>
                    )}/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      Fecha de término
                      <Tooltip className="ml-2" title="Este campo indica la fecha en la que terminó esta etapa.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  dependencies={['status']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator (rule, value) {
                        if (['pending', 'ongoing'].includes(getFieldValue('status'))) {
                          return Promise.resolve();
                        } else {
                          if (value) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(new Error('Este campo no puede estar vacío.'));
                          }
                        }
                      }
                    })
                  ]}
                  name="endDate">
                  <DatePicker format="DD/MM/YYYY" allowClear={false} showToday={false}
                    onChange={(date) => {
                      if (date) {
                        const status = this.formRef.getFieldValue('status');
                        if (status === 'ongoing') {
                          this.formRef.setFieldsValue({ status: 'finished' });
                        }
                      }
                    }}
                    renderExtraFooter={(_mode) => (
                      <div className="text-center">
                        <Button type="link" onClick={() => {
                          const status = this.formRef.getFieldValue('status');
                          if (status === 'finished') {
                            this.formRef.setFieldsValue({ endDate: null, status: 'ongoing' });
                          } else {
                            this.formRef.setFieldsValue({ endDate: null });
                          }
                        }}>
                          Borrar
                        </Button>
                      </div>
                    )}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditStageButton;
