const up = (_state) => {
  const { session } = _state;
  const { currentUser } = session;
  return {
    ..._state,
    session: {
      ...session,
      currentUser: {
        ...currentUser,
        companyId: currentUser.companyId || currentUser.company_id
      }
    }
  };
};

const down = (_state) => {
  return {};
};

export default { up, down };
