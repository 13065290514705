import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import { promiseLoader } from '../../../components/common/promise-loader';
import { impersonateUser } from '../../../store/actions/session';
import { history } from '../../../components/common/custom-router';
import { Button, Descriptions, Table } from 'antd';
import { connect } from 'react-redux';

const { Item } = Descriptions;

class Company extends Component {
  static propTypes = {
    company: object,
    impersonateUser: func
  };

  usersColumns = () => ([
    {
      title: 'Nombre',
      key: 'name',
      render: (_, { firstName, lastName }) => (<>{firstName} {lastName}</>)
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Rol',
      key: 'role',
      render: (_, { role }) => ({ admin: 'Supervisor', basic: 'Básico' }[role])
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={this.onImpersonate(record)}>
          Personificar
        </Button>
      )
    }
  ]);

  onImpersonate = (record) => () => {
    const { impersonateUser } = this.props;
    impersonateUser(record.id).then(() => {
      history.push('/staff/sale_processes');
    });
  };

  render () {
    const { company: { id, name, email, phoneNumber, rut, users, tags } } = this.props;
    return (
      <>
        <Descriptions title={name} size="small" columns={3} layout="vertical" bordered className="mb-2">
          <Item label="Nombre" span={3}>{name}</Item>
          <Item label="Email">{email}</Item>
          <Item label="Teléfono">{phoneNumber || '-'}</Item>
          <Item label="RUT">{rut || '-'}</Item>
          <Item label="Tags">{tags.join(', ')}</Item>
        </Descriptions>
        <Link to={`/support/companies/${id}/edit`}>
          <Button>
            Editar
          </Button>
        </Link>
        <h3>Usuarios</h3>
        <Link to={`/support/companies/${id}/users/new`}>
          <Button>
            Crear usuario
          </Button>
        </Link>
        <Table
          className="mt-2"
          locale={{ emptyText: 'Sin usuarios' }}
          showSorterTooltip={false}
          dataSource={users}
          columns={this.usersColumns()}
        />
      </>
    );
  }
}

export const mapDispatchToProps = {
  impersonateUser
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.support.getCompany(id);
    },
    'company'
  )
)(Company);
