import React, { Component } from 'react';

import ProcessTypeForm from '../../../components/staff/process-type-form';

class ProcessTypeCreate extends Component {
  render () {
    return (
      <>
        <h2>Crear tipo de proceso</h2>
        <ProcessTypeForm action="create"/>
      </>
    );
  }
}

export default ProcessTypeCreate;
