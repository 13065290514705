import React, { Component } from 'react';
import { Badge } from 'antd';
import { string } from 'prop-types';
import { getStatusText } from '../../../models/stages';

class StatusBadge extends Component {
  static propTypes = {
    status: string
  };

  getStatus = () => {
    const { status } = this.props;
    switch (status) {
      case 'pending':
        return 'default';
      case 'finished':
        return 'default';
      case 'cancelled':
        return 'default';
      case 'ongoing':
        return 'processing';
    }
  };

  getText = () => {
    const { status } = this.props;
    return getStatusText(status);
  };

  getColor = () => {
    const { status } = this.props;
    switch (status) {
      case 'pending':
        return 'lightgray';
      case 'finished':
        return 'blue';
      case 'cancelled':
        return 'red';
      case 'ongoing':
        return 'green';
    }
  };

  render () {
    return (
      <Badge status={this.getStatus()} text={this.getText()} color={this.getColor()}/>
    );
  }
}

export default StatusBadge;
