import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import { Layout } from 'antd';

import StaffLayout from '../staff/layout';
import SupportLayout from '../support/layout';
import CustomRouter from '../common/custom-router';
import ROUTES from '../../config/routes';

import './index.scss';

const buildRoutes = (route, path) => {
  let finalRoutes = [];
  const { component, routes } = route;
  if (routes) {
    Object.keys(routes).forEach((key) => {
      if (key.startsWith('/')) {
        finalRoutes = [...finalRoutes, ...buildRoutes(routes[key], path + key)];
      }
    });
  }
  if (component) {
    finalRoutes.push(
      <Route key={path} path={path} component={component} exact/>
    );
  }
  return finalRoutes;
};

class App extends Component {
  render () {
    const buyersRoutes = buildRoutes(ROUTES['/buyers'], '/buyers');
    const sellersRoutes = buildRoutes(ROUTES['/sellers'], '/sellers');
    const staffRoutes = buildRoutes(ROUTES['/staff'], '/staff');
    const supportRoutes = buildRoutes(ROUTES['/support'], '/support');
    const rootRoutes = buildRoutes(ROUTES['/'], '/');
    return (
      <CustomRouter>
        <Switch>
          {rootRoutes}
          <Route path="/sellers">
            <Switch>
              {sellersRoutes}
            </Switch>
          </Route>
          <Route path="/buyers">
            <Switch>
              {buyersRoutes}
            </Switch>
          </Route>
          <Route path="/staff">
            <Layout className="h-100">
              <StaffLayout routes={staffRoutes}/>
            </Layout>
          </Route>
          <Route path="/support">
            <Layout className="h-100">
              <SupportLayout routes={supportRoutes}/>
            </Layout>
          </Route>
        </Switch>
      </CustomRouter>
    );
  }
}

export default hot(App);
