import React, { Component } from 'react';
import { array } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import { Button, Table, Modal, message } from 'antd';

import API from '../../api';
import { promiseLoader, refreshAll } from '../../components/common/promise-loader';
import deleteIcon from '../../assets/images/sales-processes-delete.svg';
import editIcon from '../../assets/images/edit-icon.svg';

class Users extends Component {
  static propTypes = {
    users: array
  };

  onDelete = (record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar este usuario?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        await API.staff.deleteUser(record.id);
        message.success('Usuario eliminado correctamente.');
        refreshAll();
      }
    });
  };

  render () {
    const { users } = this.props;
    const columns = [
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
      },
      {
        title: 'Rol',
        key: 'role',
        render: (_, { role }) => ({ admin: 'Supervisor', basic: 'Básico' }[role])
      },
      {
        title: 'Acciones',
        key: 'actions',
        render: (_, record) => (
          <>
            <Link to={`/staff/users/${record.id}/edit`}>
              <Button type="link" className="px-0">
                <img src={editIcon} alt="edit icon"/>
              </Button>
            </Link>
            <Button type="link" onClick={this.onDelete(record)}>
              <img src={deleteIcon} alt="delete icon"/>
            </Button>
          </>
        )
      }
    ];
    return (
      <>
        <h1 className='text-center mt-3'>Listado de Usuarios</h1>
        <Link to="/staff/users/new">
          <Button className="mb-2">
            Crear Usuario
          </Button>
        </Link>
        <Table
          className="mt-2"
          locale={{ emptyText: 'Sin usuarios' }}
          showSorterTooltip={false}
          dataSource={users}
          columns={columns}
        />
      </>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    () => API.staff.getUsers(),
    'users'
  )
)(Users);
