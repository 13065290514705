import React, { Component } from 'react';
import { Col, Form, Input, AutoComplete, Cascader, Divider, Select } from 'antd';
import { bool, array, func } from 'prop-types';
import { promiseLoader } from '../../common/promise-loader';
import API from '../../../api';
import { COMMUNES_OPTIONS } from '../../../constants/address.js.erb';
import { NATIONALITY } from '../../../constants/nationality.js.erb';
import { cascadeFilter } from '../../../utils/filters';

const { Option } = Select;

class ClientAttributes extends Component {
  static propTypes = {
    seller: bool,
    getForm: func,
    clients: array
  };

  state = {
    options: [],
    selected: false
  };

  filterRuts = (value) => {
    if (!value) return [];

    const { clients } = this.props;

    return clients.filter(({ rut }) => {
      return rut.replace('.', '').replace('-', '').includes(
        value.replace('.', '').replace('-', '')
      );
    }).map(({ rut }) => ({ value: rut }));
  };

  onSearch = (value) => {
    const { getForm } = this.props;
    this.setState({ options: this.filterRuts(value), selected: false });
    getForm().setFieldsValue({ [this.getPrefix()]: { id: null } });
  };

  getPrefix = () => {
    const { seller } = this.props;

    if (seller) {
      return 'sellerAttributes';
    } else {
      return 'buyerAttributes';
    }
  };

  onSelect = (value) => {
    const { getForm, clients } = this.props;
    this.setState({ selected: true });
    const client = clients.find(({ rut }) => rut === value);
    getForm().setFieldsValue({
      [this.getPrefix()]: {
        ...client,
        regionCommune: [client.region, client.commune]
      }
    });
  };

  render () {
    const { seller } = this.props;
    const { options, selected } = this.state;

    const prefix = this.getPrefix();
    let title;

    if (seller) {
      title = 'vendedor';
    } else {
      title = 'comprador';
    }
    return (
      <>
        <Col span={24}>
          <Divider orientation="left">Datos {title}</Divider>
        </Col>
        <Form.Item label="ID" name={[prefix, 'id']} hidden>
          <Input disabled={selected}/>
        </Form.Item>
        <Col span={6}>
          <Form.Item label="RUT" name={[prefix, 'rut']} >
            <AutoComplete options={options} onSearch={this.onSearch} onSelect={this.onSelect}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nombres" name={[prefix, 'firstName']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Apellidos" name={[prefix, 'lastName']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email" name={[prefix, 'email']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Teléfono" name={[prefix, 'phoneNumber']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="País de origen" name={[prefix, 'nationality']}>
            <Select placeholder="Seleccione país de origen">
              {
                NATIONALITY.map((nationality) => (
                  <Option value={nationality} key={`nation-${nationality}`}>{nationality}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Ocupación/Profesión" name={[prefix, 'occupation']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Dirección" name={[prefix, 'street']}>
            <Input disabled={selected}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Región/Comuna" name={[prefix, 'regionCommune']}>
            <Cascader options={COMMUNES_OPTIONS} showSearch={{ filter: cascadeFilter }} autoComplete="new-street-address" disabled={selected} placeholder="Seleccione región y comuna"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Email Interesados" name={[prefix, 'interestedEmails'] }>
            <Select mode="tags" options={options} placeholder="Ingrese emails de interesados"/>
          </Form.Item>
        </Col>

      </>
    );
  }
}

export default promiseLoader(
  () => API.staff.getClients(),
  'clients'
)(ClientAttributes);
