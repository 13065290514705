import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { object } from 'prop-types';
import { connect } from 'react-redux';

class Home extends Component {
  static propTypes = {
    user: object
  };

  render () {
    const { user } = this.props;
    if (user) {
      return <Redirect to="staff/sale_processes"/>;
    } else {
      return <Redirect to="staff/login"/>;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser
});

export default connect(mapStateToProps)(Home);
