import React, { Component } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import API from '../../../api';
import SaleProcess from '../../../components/clients/sale-process';
import { promiseLoader } from '../../../components/common/promise-loader';

class BuyersSaleProcess extends Component {
  static propTypes = {
    saleProcess: object
  };

  render () {
    const { saleProcess } = this.props;

    return (
      <SaleProcess saleProcess={saleProcess}/>
    );
  }
}

export default compose(
  withRouter,
  promiseLoader(
    ({ match: { params: { id } } }) => {
      return API.buyers.getSaleProcess(id);
    },
    'saleProcess'
  )
)(BuyersSaleProcess);
