import React, { Component } from 'react';
import { Alert, Button, Form, Input, Select } from 'antd';
import { string, object } from 'prop-types';
import API from '../../../api';
import { history } from '../../common/custom-router';
import { Link } from 'react-router-dom';

class CompanyForm extends Component {
  static propTypes = {
    action: string,
    company: object
  };

  state = {
    error: null
  };

  handleSubmit = (company) => {
    const { action } = this.props;
    this.setState({ error: null });
    let promise;
    if (action === 'create') {
      promise = API.support.createCompany(company).then(({ id }) => {
        history.push(`/support/companies/${id}`);
      });
    } else {
      const { company: { id } } = this.props;
      promise = API.support.updateCompany(id, company).then(() => {
        history.push(`/support/companies/${id}`);
      });
    }
    promise.catch((err) => {
      this.setState({ error: err.message });
    });
  };

  getInitialValues = () => {
    const { company } = this.props;
    if (company) {
      return company;
    } else {
      return {
        name: '',
        rut: '',
        phoneNumber: '',
        email: '',
        tags: []
      };
    }
  };

  render () {
    const { action, company } = this.props;
    const { error } = this.state;

    return (
      <>
        {
          error &&
          <Alert
            message="Error al editar empresa"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
            closable
          />
        }
        <Form onFinish={this.handleSubmit} initialValues={this.getInitialValues()} labelCol={ { span: 8 } } wrapperCol={ { span: 16 } } autocomplete="off">
          <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'El nombre no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Rut" name="rut">
            <Input/>
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'El correo no puede estar vacío.' }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Teléfono" name="phoneNumber">
            <Input/>
          </Form.Item>
          <Form.Item label="Tags" name="tags">
            <Select mode="tags" allowClear/>
          </Form.Item>
          <Form.Item wrapperCol={ { span: 16, offset: 8 }}>
            <Link to={action === 'create' ? '/support/companies' : `/support/companies/${company.id}`}>
              <Button type="secondary" className="mr-2">
                Cancelar
              </Button>
            </Link>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default CompanyForm;
