export const STATUSES = ['pending', 'ongoing', 'finished', 'cancelled'];

export const getStatusText = (status) => {
  switch (status) {
    case 'pending':
      return 'Sin iniciar';
    case 'ongoing':
      return 'En curso';
    case 'finished':
      return 'Finalizado';
    case 'cancelled':
      return 'Cancelado';
    default:
      return 'Indefinido';
  }
};
