import axios from 'axios';
import { history } from '../components/common/custom-router';
import { camelizeKeys, snakeKeys } from '../utils/cases';
import { Staff } from './staff';
import { Support } from './support';
import { mergeObjects } from '../utils/objects';
import { Sellers } from './sellers';
import { Buyers } from './buyers';

export class API {
  constructor () {
    this.store = null;
    this.staff = new Staff(this);
    this.support = new Support(this);
    this.sellers = new Sellers(this);
    this.buyers = new Buyers(this);
  }

  setStore = (store) => {
    this.store = store;
  };

  getTokenFromStore = () => {
    const {
      session: { token }
    } = this.store.getState();
    return token;
  };

  getBaseHeaders = () => {
    const token = this.getTokenFromStore();
    const baseHeaders = {};

    if (token) {
      return {
        ...baseHeaders,
        Authorization: `Bearer ${token}`
      };
    } else {
      return baseHeaders;
    }
  };

  baseConfig = async () => ({
    headers: await this.getBaseHeaders()
  });

  delete = async (url, params, extra) => {
    try {
      return camelizeKeys(await axios.delete(url, mergeObjects(await this.baseConfig(), extra)));
    } catch (e) {
      const { response: { status } } = e;
      if (status === 404) {
        history.push('/not-found');
      } else { throw e; }
    }
  };

  get = async (url, params, extra = {}) => {
    try {
      return camelizeKeys(await axios.get(url, mergeObjects(await this.baseConfig(), extra)));
    } catch (e) {
      const { response: { status } } = e;
      if (status === 404) {
        history.push('/not-found');
      } else { throw e; }
    }
  };

  post = async (url, params, extra = {}) => {
    const formData = params instanceof FormData ? params : snakeKeys(params, ['_destroy']);
    try {
      return camelizeKeys(await axios.post(url, formData, mergeObjects(await this.baseConfig(), extra)));
    } catch (e) {
      const { response: { status } } = e;
      if (status === 404) {
        history.push('/not-found');
      } else { throw e; }
    }
  };

  put = async (url, params, extra = {}) => {
    const formData = params instanceof FormData ? params : snakeKeys(params, ['_destroy']);
    try {
      return await camelizeKeys(axios.put(url, formData, mergeObjects(await this.baseConfig(), extra)));
    } catch (e) {
      const { response: { status } } = e;
      if (status === 404) {
        history.push('/not-found');
      } else { throw e; }
    }
  };

  patch = async (url, params, extra = {}) => {
    const formData = params instanceof FormData ? params : snakeKeys(params, ['_destroy']);
    try {
      return await camelizeKeys(axios.patch(url, formData, mergeObjects(await this.baseConfig(), extra)));
    } catch (e) {
      const { response: { status } } = e;
      if (status === 404) {
        history.push('/not-found');
      } else { throw e; }
    }
  };

  // client

  getClientSaleProcess = (id) => this.get(`/api/staff/client/sale_processes/${id}`).then(({ data }) => data);
}

export default new API();
